import React, {Component, useState, useEffect} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";

import Section from "../layouts/Section";
import KeyboardView from "../customComponents/KeyboardView";


import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, money, moneyStatus} from '../../utility/Helper1';

import {depositActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';

import {CopyToClipboard} from 'react-copy-to-clipboard';


import { AiOutlineWallet, AiFillCloseCircle, AiOutlineCopy, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import styled from 'styled-components';
import MobileView from '../customComponents/MobileView';



function DepositHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]   = useState('Loading history...');

    const [isOpen, setOpen] = useState(false);

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);

    const finance = useSelector(homeScreenStatSelect);
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const [currencyModal, setCurrencyModal]   = useState('');

    const allDepositData = useSelector(allDepositSelect);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);


    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);


    const search = useLocation();
    const depositRequest = search?.state?.deposit;


    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const progClickPaymentUpload = () => {
        uploadPayment.current.click();
    }

    const updateCurrencySelected = (newCurObj) => {
        setCurrencySelected(newCurObj)
    }

    const toggleCurrencyModal = () => {
        setCurrencyModal(!currencyModal)
    }





    useEffect(() => {
        getPendingPayments()
    }, [])


    const getPendingPayments = () => {
        
        // setloading(true)
        // setLoadingText('Refreshing deposit records...')

        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            // notify(response.msg, response.code)
        }))

    }


     const uploadSelectedPayment = (file) => {
        
        setLoadingText('Uploading payment...')
        setloading(true)

        dispatch(depositActions.onCreateDeposit({txn_id:getPendingPayment?.[0]?.txn_id, image:file}, "deposit/manual-deposit-upload", response => {
            setloading(false)
            notify(response.msg,  response.code)
        }))

    }




    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);

    const processDeposit = (e) => {
        
        e.preventDefault();

        if((isNaN(amount)) || (amount == 0)){
            notify('Enter amount', 0);
            return false;
        }

        setloading(true)

        setLoadingText('Processing request...')

        dispatch(depositActions.onCreateDeposit({amount, currency: finance?.[0].motherType}, "deposit/new", response => {
            if(response.code == 1){
                setAmount('')
            }else{
                notify(response.msg, response.code)
            }

            setloading(false)
            
        }))
    }







    const deleteInv = (txn_id) => {

        if(getPendingPayment?.[0]?.status != 0){
            notify('Cannot be deleted as payment is being processed.', 0)
            return;
        }

        setloading(true)
        setLoadingText('Deleting request...')

        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {
            if(response.code == 1){
                // setWhichModal(1)
                // setOpenModal(true)
            }
            setloading(false)
        }))

    }


    const thePendingPayment = () => {

        let displayableAmount = getPendingPayment?.[0]?.useCryptoValue == 1?`${getPendingPayment?.[0]?.amountEnforcedToPay} ${getPendingPayment?.[0]?.abbr}`:`${getPendingPayment?.[0]?.amountEnforcedToPay} USDT TRC20`
        let curDetails = finance?.filter((filitem, index) => filitem.type == getPendingPayment?.[0]?.currency)
        let perfectWalletobj;
        let eachParam;


        if((getPendingPayment?.[0]?.createdWithAutoOrManual == 0) && (getPendingPayment?.[0]?.autoConfirm == 2)){
            let pmAddress = JSON.parse(getPendingPayment?.[0]?.address);
            eachParam = Object.keys(pmAddress.param);
            perfectWalletobj = pmAddress
        }
        

        return <div className="ba-bill-pay-inner" style={{border: 'none', backgroundColor: 'transparent'}}>
            
            <div style={{marginBottom: 20}}>
                <h1 className="text-white">Deposit Instruction</h1>
            </div>
 
            <div className="ba-single-bill-pay">


                <div className="details" style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{display: 'flex'}}>
                        <span style={{color: '#fff'}}>

                            {getPendingPayment?.[0]?.txn_id}

                        </span>
                        <span className="text-info-1" style={{marginLeft: 'auto'}}>
                            
                        </span>
                    </p>
                    <p style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span style={{color: '#fff'}}>
                            Status: {moneyStatus()[getPendingPayment?.[0]?.status]}
                        </span>
                        <span>

                            
                            {getPendingPayment?.[0]?.createdWithAutoOrManual == 0 && getPendingPayment?.[0]?.autoConfirm == 2 && <span>
                                <form action={perfectWalletobj?.form_url} method="POST" style={{display: 'inline'}}>
                                    {eachParam.map((eachkey, index) => (
                                            <input name={eachkey} value={perfectWalletobj.param[eachkey]} type="hidden" />
                                        ))}
                                    <button style={{textDecoration: 'underline', color: '#fff'}} type="submit">Pay Now</button>
                                </form>

                                </span>}

                        </span>
                    </p>
                </div>

            </div>



            <div className="amount-inner">
                
                <div className="flexify" style={{marginBottom: 10}}>
                    <h5 className="text-info-1"></h5>
                    {(getPendingPayment?.[0]?.autoConfirm == 1) && 1>2 && (getPendingPayment?.[0]?.createdWithAutoOrManual == 0) && <small className="text-info-1">{getPendingPayment?.[0]?.blockConfirmationsAchieved} Block Confirmation</small>}
                </div>

                {(getPendingPayment?.[0]?.autoConfirm == 1 || 1==1) && <div className="text-center">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div>

                                <h6>
                                    <CopyToClipboard text={getPendingPayment?.[0]?.address}
                                        onCopy={() => notify('Copied', 1)}>
                                        <span className="text-white cursor-pointer">
                                            {getPendingPayment?.[0]?.address} <AiOutlineCopy />
                                        </span>
                                    </CopyToClipboard>
                                </h6>

                                <small style={{color: '#fff'}}>Only deposit {displayableAmount} to the displayed address</small><br/>
                                <small className="text-danger">{getPendingPayment?.[0]?.paymentMessage}</small><br/>




                                    {getPendingPayment?.[0]?.amountEnforcedToPay != '' && getPendingPayment?.[0]?.amountEnforcedToPay != 0 && <span className="flexify" style={{color: '#fff'}}>
                            
                                        <h2 style={{marginRight: 10, color: '#fff'}}>
                                            {displayableAmount}
                                        </h2>

                                        <CopyToClipboard text={getPendingPayment?.[0]?.amountEnforcedToPay} onCopy={() => notify('Copied', 1)}>
                                            <AiOutlineCopy className="cursor-pointer" />
                                        </CopyToClipboard>

                                    </span>}



                               
                        </div>

                    </div>

                    <div className="text-center" style={{}}>
                        {/*<div style={{backgroundColor: '#3629b7', display: 'inline-block', marginTop: 20}}>
                            <Images style={{fill: "red"}} src={getPendingPayment?.[0]?.qrCode} />
                        </div>*/}


                        <div className="text-white cursor-pointer" style={{marginTop: 40}} onClick={() => window.confirm('Click OK to confirm delete') && deleteInv(getPendingPayment?.[0]?.txn_id)}>
                            Delete
                        </div>

                        <div>
                            <div style={{backgroundColor: '#fff', marginTop: 30, display: 'inline-block'}}>
                                <Images height={'80px'} style={{fill: "red"}} alt="QR Code" src={getPendingPayment?.[0]?.qrCode} />
                            </div>
                        </div>

                    </div>



                    <div style={{marginTop: 20}}>

                        {getPendingPayment?.[0]?.createdWithAutoOrManual == 1 && <h2>
                            <span className="h1 flexify" onClick={() => progClickPaymentUpload()}  style={{cursor: 'pointer', color: '#fff'}}>
                                <AiOutlineCloudUpload size={18} /> <span style={{marginLeft: 4}}>{(getPendingPayment?.[0]?.image == null)?'Upload':'Re-upload'} Payment</span>
                            </span>
                            <input style={{display: 'none'}} ref={uploadPayment} onChange={(e) => uploadSelectedPayment(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
                        </h2>}

                    </div>

                </div>}


                {getPendingPayment?.[0]?.autoConfirm == 0 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h6 className="text-info-1">
                        {curDetails?.[0]?.accountNameText}
                    </h6>

                    <h6>
                        <span className="text-info cursor-pointer">
                            {curDetails?.[0]?.accountName}
                        </span>
                    </h6>

                    
                </div>}

                {getPendingPayment?.[0]?.autoConfirm == 0 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h6 className="text-info-1">
                        {curDetails?.[0]?.accountNumberText}
                    </h6>

                    <h6 className="text-info-1">
                        <CopyToClipboard text={curDetails?.[0]?.accountNumber}
                            onCopy={() => notify('Copied', 1)}>
                            <span className="text-info cursor-pointer">
                                {curDetails?.[0]?.accountNumber}
                                <AiOutlineCopy />
                            </span>
                        </CopyToClipboard> | <span className="text-info cursor-pointer">{curDetails?.[0]?.bankName}</span>
                    </h6>

                    
                </div>}

            </div>

        </div>


    }



    const handleKeyPress = (number) => {
        setAmount(number)
    }



    const newPayment = () => {

        return <div>

               <MobileView>

                    <form style={{}} action="">
                           
                        <h1 className="text-center text-white" style={{fontSize: 80, marginBottom: 50}}>
                            {1>2 && money('', finance?.[0]?.motherType, {symbolOnly: true})}${amount}
                        </h1>
                  

                        <div>


                            <KeyboardView getActiveValue={handleKeyPress} />

                            <button type="submit" onClick={processDeposit} disabled={loading} className="cursor-pointer passion-font h3 tn t-40 tn btn-block btn-info" 
                                style={{border: 'none', fontSize: 14, padding: 20, backgroundColor: '#fd8c48', marginTop: 20}}>
                            SUBMIT </button>

                            <br/>
                            <br/>
                        
                        </div>

                    </form>

                </MobileView>




            </div>

    }












    return (

        <SiteWide id="main" title={"Add Fund"}>
            <Section overlayText={loadingText} loading={loading} title={'Add Fund'} style={{}}>
                
                
                
                    {(getPendingPayment?.length ?? 0) == 0?newPayment():thePendingPayment()}
                


            </Section>

        </SiteWide>
    );
}

export default DepositHistory;


