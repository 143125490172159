import React, {useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import { allRemoteSettingsSelect, userSelect } from '../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import Colors from '../../utility/Colors';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Modal from 'react-modal';
import MobileView from './MobileView';


function SiteWide(props) {

    const {title, description, children, ...rest} = props
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [testimony, setTestimony]   = useState('');
    const [testimonyTitle, setTestimonyTitle]   = useState('New testimony');

    const [submittingTestimony, setSubmittingTestimony]   = useState(false);
    const [writeTestimony, setWriteTestimony]   = useState(false);
    const loadUsers = useSelector(userSelect);

        const dispatch = useDispatch();

    useEffect(() => {

        if((loadUsers?.writeTestimonyNow ?? 0) == 1){
            setWriteTestimony(true)
        }

    }, [loadUsers])

    const centeredModal = {
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          overflowY: 'scroll'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            border: 'none',
            zIndex: 10

        },
    };

    const titleToUse = title ?? site_settings?.site_settings?.title ?? 'welcome';
    const descToUse = description ?? site_settings?.site_settings?.description ?? 'You are here';
    const siteName = site_settings?.site_settings?.sitename ?? 'dotcom';
    const url = site_settings?.site_settings?.site_url ?? 'dotcom';
    const logo = site_settings?.site_settings?.logo ?? '';


    const submitTestimony = () => {

        setSubmittingTestimony(true)

        dispatch(authActions.onLogin(`testimony/new`, {testimony, title: testimonyTitle}, response => {

            if(response.code == 1){
                setWriteTestimony(false)

            }

            setSubmittingTestimony(false)

        }))
        
    }


    return (
        <HelmetProvider>
            <LoadingOverlay
                active={site_settings?.masterIsLoading ?? true}
                spinner
            >
                <Helmet>

                    <meta name="theme-color" content={Colors.all.header} />

                    <title>{titleToUse}</title>
                    <link rel="icon" type="image/x-icon" href={logo} />
                    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                    <meta name="description" content={descToUse} />

                    <meta property="og:title" content={titleToUse} />
                    <meta property="twitter:title" content={titleToUse} />
          
                    <meta property="og:site_name" content={siteName} />
                    <meta property="twitter:site" content={siteName} />

                    <meta property="og:description" content={descToUse} />
                    <meta property="twitter:description" content={descToUse} />
          
                    <meta property="og:type" content="website" />

                    <meta property="og:url" content={url} />

                    <meta property="og:image" content={logo} />
                    <meta property="twitter:image" content={logo} />

                    <meta name="twitter:card" content="summary_large_image" />

                </Helmet>


                    <div {...rest}  style={{paddingLeft: 15, paddingRight: 15, paddingTop: props?.paddingTop ?? 80, background: Colors.all.header, ackgroundImage: "url(" + (props.backgroundImage ?? require('../../images/main_bg.jpg')) + " )", backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
                       {children}
                    </div>



                <Modal
                    isOpen={writeTestimony}
                    onRequestClose={() => {}}
                    style={centeredModal}
                    contentLabel="Testimony Modal"
                    shouldCloseOnOverlayClick={false}
                >
                    
                    <div class="contid" style={{width: '100%'}}>
                            <div class="row">
                                
                                <div class="col-lg-12 col-md-12 mb-4">
                                  <div class="card crd1 h-100" style={{backgroundColor: Colors.all.bluey,}}>
                                    <div class="card-body">
                                     
                                        <h5 class="card-title text-white">Submit testimony</h5>
                                        

                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            
                                            <textarea maxLength={300} style={{height: 200}} value={testimony}  onChange={(e) => setTestimony(e.target.value)} />

                                        </div>


                                        <div class="d-grid my-3">
                                            <button disabled={submittingTestimony} onClick={submitTestimony} class="btn btn-outline-dark btn-block" style={{backgroundColor: '#fff', color: '#000'}}>
                                                {submittingTestimony?'Please wait...':'Submit'}
                                            </button>
                                        </div>

                                    </div>

                                    
                                  </div>
                                </div>


                            </div>    

                    </div>

                </Modal>


            </LoadingOverlay>
        </HelmetProvider>
    );
}

export default SiteWide;