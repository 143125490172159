import React from 'react';



function Countdown({fromThisTime, timeZeroCallback}) {

  

  const [theCountdown, setTheCountdown] = React.useState(0);


  React.useEffect(() => {
    
      // Set the date we're counting down to
      let countDownDate = new Date(fromThisTime).getTime();

      // Update the count down every 1 second
      // let x = setInterval(function() {
      let x = setTimeout(function() {

      // Get todays date and time
      let now = new Date().getTime();
      // let converted = new Date().toLocaleString('en-US', { timeZone: 'Africa/Lagos'});
      // let now = new Date(converted);

      // Find the distance between now an the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      // days + "d " + 
      setTheCountdown(hours + "h " + minutes + "m " + seconds + "s ");

      // If the count down is finished, write some text
      if (distance < 0) {
        setTheCountdown("0");
        timeZeroCallback();
        return () => clearTimeout(x);
      }
    }, 1000);

    return () => clearTimeout(x);

  });

  return(
    <span>{theCountdown}</span>
  )

  

}


export default Countdown;