export const LOGIN_SUCCESS 	= "LOGIN_SUCCESS";
export const LOGIN 			= "LOGIN";
export const TOKEN 			= "TOKEN";
export const LOGOUT 		= "LOGOUT";
export const SIGN_UP 		= "SIGN_UP";
export const CLEAR_USER_DATA 		= "CLEAN_USER_DATA";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_TOKEN = "UPDATE_TOKEN";


export const HOME_SCREEN_STAT 		= "HOME_SCREEN_STAT";
export const CLEAR_HOME_SCREEN_STAT 		= "CLEAR_SCREEN_STAT";
export const CLEAR_ALL_REDUCERS_DATA 		= "CLEAR_ALL_REDUCERS_DATA";
export const RELOAD_SYSTEM_DATA 		= "RELOAD_ACCOUNT_DATA";
export const RESEND_VERIFY_EMAIL 		= "RESEND_VERIFY_EMAIL";
export const VERIFY_EMAIL 		= "VERIFY_EMAIL";


export const SAVE_REMOTE_SITE_SETTINGS 		= "SAVE_REMOTE_SITE_SETTINGS";
export const SAVE_TICKER_HOME 			= "SAVE_TICKER_HOME";

export const FETCH_FX_HEAD 		= "FETCH_FX_HEAD";
export const SAVE_FX_HEAD 		= "SAVE_FX_HEAD";



export const SAVE_DEPOSIT_DATA 		= "SAVE_DEPOSIT_DATA";
export const MANAGE_DEPOSIT 		= "MANAGE_DEPOSIT";
export const ALL_DEPOSIT 			= "ALL_DEPOSIT";



export const SAVE_WITHDRAWAL_DATA 		= "SAVE_WITHDRAWAL_DATA";
export const MANAGE_WITHDRAWAL 			= "MANAGE_WITHDRAWAL";
export const ALL_WITHDRAWALS 			= "ALL_WITHDRAWALS";






