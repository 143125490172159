import React, {Component} from "react";
import Colors from '../../utility/Colors';
import {WebLink} from "../customComponents";
import { AiOutlineExpand, AiFillSkin, AiFillSwitcher, AiFillSketchCircle, AiTwotoneHome, AiFillTrophy } from "react-icons/ai";

class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer-area">
                    { 1>2 &&
                    <div className="footer-top text-center" style={{backgroundImage: "url(assets/img/bg/7.png)"}}>
                        <div className="container">
                            <p>Copyright © Bankapp 2020. All Rights Reserved. Designed By S7template</p>
                        </div>
                    </div>
                    }
                    <div className="container">
                        <div className="footer-bottom" 
                            style={{background: Colors.footerColor}}
                        >
                            <ul style={{display: 'flex', color: '#cecece'}}>
                                <li className="each-bottom-menu">
                                    <WebLink className="home-clicked" to={'/'}>
                                        <AiTwotoneHome size={25} color="#fff" />
                                        <p className="text-white">Home</p>
                                    </WebLink>
                                </li>

                                <li className="each-bottom-menu">
                                    <WebLink to={'/withdraw'}>
                                        <AiFillTrophy size={25} color="#fff" />
                                        <p className="text-white">Cash-out</p>
                                    </WebLink>
                                </li>






                                <li className="each-bottom-menu">
                                    <WebLink to={'/trade'}>
                                        <p></p>
                                    </WebLink>
                                </li>

                                <WebLink to={'/trade'}>
                                    <li className="each-bottom-menu" style={{justifyContent: 'center', display: 'flex', alignItems: 'center', background: Colors.all.bluey, position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', marginBottom: 30, borderRadius: 120, width: 60, height: 60}}>
                                        <AiOutlineExpand size={25} color="#fff" />
                                    </li>
                                </WebLink>







                                <li className="each-bottom-menu">
                                    <WebLink to={'/wheel-of-fortune'}>
                                        <AiFillSketchCircle size={25} color="#fff" />
                                        <p className="text-white">Fortune</p>
                                    </WebLink>
                                </li>


                                <li className="each-bottom-menu">
                                    <WebLink to={'/user-setting'}>
                                        <AiFillSkin size={25} color="#fff" />
                                        <p className="text-white">Profile</p>
                                    </WebLink>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>

                {/* Back to Top Area */}
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up" /></span>
                </div>
            </>
        );
    }
}

export default Footer;