import React, {Component} from "react";
import {WebLink} from "../customComponents";
import Colors from '../../utility/Colors';
import {useHistory} from "react-router-dom";
import MobileView from '../customComponents/MobileView';
import { AiOutlineArrowLeft } from "react-icons/ai";

function Header(props) {
    
        const history = useHistory();


        return (
            

                <div className="header-area"  style={{backgroundColor: '#000', position: 'fixed', left: 0, top: 0, idth: 'vw'}}>
                    
                        <div className="row" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div className="col-sm-2 col-2 text-center">
                                <WebLink className="menu-back-page home-clicked"  onClick={() => history.goBack()}>
                                    <AiOutlineArrowLeft />
                                </WebLink>
                            </div>
                            <div className="col-sm-8 col-8 text-center">
                                <div className="page-name bebas-text h3">{props.title}</div>
                            </div>
                            <div className="col-sm-2 col-2 text-right">
                                <div className="page-name">{props.rightTopMenu}</div>
                            </div>
                        </div>

                </div>



            
        );

}

export default Header;