import React, {Component} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {WebLink, SiteWide} from "./customComponents";

import Slider from "react-slick";
import {Images} from "./customComponents";
import Colors from '../utility/Colors';

import {capitalizeFirstLetter, money, niceDate} from '../utility/Helper1';


import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';

import AutoNotification from "./layouts/AutoNotification";
import { AiFillBell, AiOutlineBell } from "react-icons/ai";
import Marquee from 'react-marquee-master';
import './homestyle.css';

    
const fixtureHalfBgImg = require('../images/lawn.jpg');
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
        <div className="owl-next la la-angle-right "  onClick={onClick}/>
    </div>  
  );
}




function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
        <div className=" owl-prev la la-angle-left " onClick={onClick}/>
    </div>
  );
}


function Home() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);

    const dispatch = useDispatch();
    const finance = useSelector(homeScreenStatSelect);


    
    const transactionHistory = () => {

        let dataToRender = site_settings?.testimony;

        let mapArray = dataToRender?.map((item, index) => {
            return <div style={{width: '100%', backgroundColor: Colors.all.bluey, paddingLeft: 20, display:'flex', justifyContent: 'center', alignItems: 'center', flex: 4, paddingBottom: 20}}>
                <div style={{fex: 1}}>
                    <Images style={{width: 50, height: 50, borderRadius: 40}} className="" source={item.uimage ?? require('../images/avatar.png')} />

                </div>
                <div style={{flex: 1, marginLeft: 10}}>

                    <h6 className="bebas-text" style={{color: '#ff5722', fontWeight: 900, fontSize: 13}}>{item.username}</h6>
                    <h6 className="text-white bebas-text">{item.testimony}</h6>
                    <span className="text-white bebas-text">{niceDate(item.timeApproved)}</span>

                </div>



            </div>
        })

        return mapArray;
    }




    // main slider
    const karosettings = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 1000,
        cssEase: "ease-in-out",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };




    const dashGrids = [
        {bg: '#f7f3e4', title: 'Fund',      img: 'icon-e-light.png', to: '/deposit'},
        {bg: '#fd8c48', title: 'Withdraw',  img: 'icon-l.png', to: '/withdraw'},
        {bg: '#bfc0ef', title: 'Bot',     img: 'icon-m.png', to: '/bot'},
        {bg: '#4a58ca', title: 'Wallet',     img: 'icon-n.png', to: '/wallet'},

    ]


    function truncateWithEllipsis(str, charCount) {
      if (str.length <= charCount) {
        return str;
      }

      const truncatedStr = str.slice(0, charCount) + '...';

      return truncatedStr;
    }


    const dashGridFn = (item, index) => {

        let addedStyle = '';

        if((index%2) == 0){
            addedStyle = {marginRight: 5}
        }else{
            addedStyle = {marginLeft: 5}
        }

        return <WebLink to={item.to} className="" style={{...addedStyle, flex: 1, width: '30%', background: item.bg, padding: 10, paddingBottom: 20, paddingTop: 20, marginBottom: 10, borderRadius: 8}}>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <h5 className="bebas-text" style={{color: Colors.all.blackTextHolder_1, alignSelf: 'flex-end'}}>
                        {item.title}
                    </h5>

                    <div style={{color: Colors.all.whiteTextHolder_1}}>
                        <Images style={{height: 100, width: 'auto'}} source={require(`../images/${item.img}`)} />
                    </div>

                </div>

            </WebLink>
    }

    return (

        <SiteWide>

          
            <div className="header-area" style={{backgroundColor: Colors.all.header}}>
                <div className="container">
                    <div className="row">


                        <div className="col-sm-4 col-4"></div>

                        <div className="col-sm-4 col-4 text-center">
                            <WebLink to={'/'} style={{padding: 10}} className="logo">
                                {(site_settings?.site_settings?.logo !== '') && <Images style={{height: 40}} className="cursor-pointer" source={(site_settings?.site_settings?.logo)} alt="logo" /> }
                            </WebLink>
                        </div>

                        
                        <div className="col-sm-4 col-4"></div>


                    </div>
                </div>
            </div>








            <div className="balance-area" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                    
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#1d1d1d', padding: 10, marginRight: 10, width: 40, height: 40, borderRadius: 7}}>
                       <Images style={{height: '100%'}} source={require('../images/path4.svg')} />
                    </div>

                    <WebLink to={userData?.username?'/':'/signin'} style={{}}>
                        Hello {capitalizeFirstLetter(userData?.username ?? 'Guest')},
                        <h6 className="bebas-text" style={{color: Colors.all.whiteTextHolder_1}}>{userData?.username?'Welcome back': 'Please log in'}</h6>
                    </WebLink>

                </div>

                <WebLink to={'/trade-history'} style={{}}>
                    <AiOutlineBell size={20} color="#fff" />
                </WebLink>

            </div>

            


            <div className="" style={{background: Colors.all.blue, padding: 10, paddingBottom: 20, paddingTop: 20, marginTop: 20, borderRadius: 8}}>

                <div style={{}}>

                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        
                        <div style={{color: Colors.all.whiteTextHolder_1}}>
                            Main Wallet
                        </div>

                        <Images style={{height: 40}} source={require('../images/tron.png')} />

                    </div>

                    <div style={{color: Colors.all.whiteTextHolder_1, marginTop: 30}}>
                        Your balance
                    </div>
                    <h1 style={{color: Colors.all.whiteTextHolder_1}}>
                        {money(finance?.[0]?.balance ?? 0, finance?.[0]?.motherType ?? 55)}
                    </h1>

                </div>

            </div>









            <div className="goal-aea pd-top-36">
                <div className="" style={{}}>
                    
                    <div className="setion-title" style={{display: 'flex',}}>
                        <h5 style={{}} className="text-grey bebas-text">Shortcuts</h5>
                    </div>



                    <div style={{lexDirection: 'row', padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

                        {dashGrids?.slice(0, 2).map((item, index) => dashGridFn(item, index))}

                    </div>


                    <div style={{lexDirection: 'row', padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

                        {dashGrids?.slice(2, 5).map((item, index) => dashGridFn(item, index))}

                    </div>


                </div>
            </div>






            <div className="pd-top-36">
                <div className="" style={{}}>

                    <Slider className="main-home-slider" arrows={false} {...karosettings}>
                        {site_settings?.slider_settings?.map((item, index) => (
                            <div style={{}} className="">
                                <a target="_blank" href={item?.linkWhenClicked}>
                                    <Images style={{borderRadius: 10}} resizeMode="contain" height="200" width="100%" source={item?.image} />
                                </a>
                            </div>)
                        )}
                    </Slider>


                </div>
            </div>





            <div className="pd-top-36">
                <div className="" style={{}}>
                    <div className="section-title" style={{display: 'flex',}}>
                        <h5 style={{}} className="text-grey bebas-text">24Hrs Movers</h5>
                    </div>

                    <ul style={{padding: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                        {site_settings?.ticker?.slice(0, 8)?.map((item, index) => (
                        
                            <li className="ba-single-transaction style-two bg-black-grad" style={{backgroundColor: Colors.all.greyBgHolder_1, width: 'calc(50% - 10px)', marginBottom: 20}}>
                                <div className="thub" style={{border: 0, marginRight: 10}}>
                                    <Images style={{height: 60, width: 60}} alt={`${item.name} ticker crypto`} source={item.image} />
                                </div>
                                <div className="details">
                                    <h6 className="text-white" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{truncateWithEllipsis(item.name, 8)}</h6>
                                    ${item.current_price}

                                    
                                    <span style={{marginLeft: 10}} className={((Number(item.price_change_percentage_1h_in_currency) > 0)?'text-success':'text-arning')}>{(Number(item.price_change_percentage_1h_in_currency).toFixed(2))}%</span>
                                    
                                </div>
                            </li>)
                        )}
                    </ul>
                    
                </div>
            </div>
            {/* goal area End */}




            {(site_settings?.testimony?.length ?? 0) > 0 && <div className="pd-top-36">
                <div className="" style={{}}>
                    <div className="section-title" style={{display: 'flex',}}>
                        <h5 style={{}} className="text-grey bebas-text">Testimonies</h5>
                    </div>

                    <div style={{padding: 10, ackgroundColor: 'red', width: '100%'}}>
                        
                        <Marquee marqueeItems="mbm20" marqueeContainerClassName="marquee" minHeight="350" marqueeItems={transactionHistory()} />
                    
                    </div>
                    
                </div>
            </div>}




            {/* Footer Component Imported from Layouts */}
            <Footer />
        </SiteWide>
    );
}

export default Home;