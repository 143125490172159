import React, {Component, useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {WebLink, SiteWide} from "./customComponents";
import Section from "./layouts/Section";

import Slider from "react-slick";
import {Images} from "./customComponents";
import Colors from '../utility/Colors';
import Helper1, {notify, money, niceDate} from '../utility/Helper1';

import {withdrawalActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allWithdrawalSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';

    

function DepositHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);

    const [loading, setloading]   = useState(false);
    const [amount, setAmount]   = useState();
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);

    const [enterAmountModal, setEnterAmountModal]   = useState(false);
    const [newDepositInfo, setNewDepositInfo]   = useState();
    const [changeWhichWallet, setChangeWhichWallet]   = useState(100);


    const [modalIsOpen, setIsOpen] = React.useState(false);

    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allWithdrawalSelect);


    const loadAllWithdrawal = () => {

        setloading(true)
        dispatch(withdrawalActions.onLoadAllWithdrawals("withdrawal/load", response => {

            if(response.code == 1){
                setloading(false)
            }
        }))

    }

    useEffect(() => {
        loadAllWithdrawal()
    }, [])



    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };


    const openModal = () => {
        
        if((isNaN(amount)) || (amount == 0)){
            notify('Enter amount', 0);
            return;
        }

        if(changeWhichWallet == 100){
            notify('Select preferred wallet', 0);
            return;
        }

        setPaymentPlaceLoading(true);
        dispatch(withdrawalActions.onCreateWithdrawal({amount, currency: finance?.[0]?.country}, `withdrawal/new/${changeWhichWallet}`, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                setEnterAmountModal(false)
                setAmount('');
            }
            setPaymentPlaceLoading(false)
        }))
    }


    const closeModal = () => {
        setIsOpen(false);
    }

    const whichWallet = ['Balance', 'Commission']

    const status = ['Pending', 'Processing', 'Paid', 'Cancelled'];

    return (

        <SiteWide title={"Withdrawal History"}>
            <Section back="/trade" sectionstyle={{}} loading={loading} title={'Withdrawals'}>

                <div className="goal-area pd-bottom-36">
                    <div className="containe" style={{paddingRight: 20, paddingLeft: 20}}>
                        

                        <ul className="transaction-inner">
                            {loadHistoryData?.slice(0, 100)?.map((item, index) => {

                                    let mainResultTally = item;

                                    return <li className="ba-single-transaction style-two bg-black-grad">
                                        <div className="thumb" style={{backgroundColor: 'transparent', border: 0}}>
                                            <Images style={{height: '70px'}} alt={`Withdrawal`} source={require(`../images/tron.png`)} />
                                        </div>
                                        <div className="details">
                                            <h5 className="text-white bebas-text">{money(item?.amount, item.currency)} ({whichWallet[item?.whichWallet]})</h5>
                                            <small className="text-warning bebas-text">Time: {item?.timestamp}</small><br />
                                            <small className="text-info bebas-text">Status: {status[item?.status]} 
                                            </small>

                                        </div>
                                    </li>
                                }
                            )}
                        </ul>
                        
                        {(loadHistoryData?.length ?? 0) == 0 && !loading && <div>
                                <h5 className="text-info">No withdrawal history found.</h5>

                            </div>
                        }



                    </div>
                </div>

                <Footer />

            </Section>


            

                
                <Modal
                    isOpen={enterAmountModal}
                    onRequestClose={() => setEnterAmountModal(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                  
                    <LoadingOverlay
                        active={paymentPlaceLoading}
                        spinner
                    >
                        <div className="modal-content bg-warnig">
                            <div className="modal-header">
                                <h4 className="modal-title">Process Withdrawal</h4>
                                <button type="button" className="close" onClick={() => setEnterAmountModal(false)}>
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">close</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{padding: '0 !important'}}>
                                <form className="contact-f-box" style={{}}>

                                    <label className="single-input-wrap">
                                        <span className="text-dark">Select Wallet</span>
                                        <select style={{height: 45, border: '1px solid #ececec'}} value={changeWhichWallet} onChange={e => setChangeWhichWallet(e.target.value) } required=""  className="auth-input form-control">
                                            <option hidden value="">Selects wallet</option>
                                            <option value="0">Earning Wallet</option>
                                            <option value="1">Commission Wallet</option>
                                        </select>
                                    </label>


                                    {changeWhichWallet == 0 && <label className="single-input-wrap">
                                        <span className="text-dark">Balance</span>
                                        <input disabled value={`${finance?.[0]?.balance} USDT`} className="auth-input" type="text" />

                                    </label>}

                                    {changeWhichWallet == 1 && <label className="single-input-wrap">
                                        <span className="text-dark">Commission</span>
                                        <input disabled value={`${finance?.[0]?.commission} USDT`} className="auth-input" type="text" />

                                    </label>}

                                    <label className="single-input-wrap">
                                        <span className="text-dark">Enter Withdrawal Amount</span>
                                        <input value={amount} placeholder="USDT Amount" onChange = {e => setAmount(e.target.value) } required="" className="auth-input" type="text" />

                                    </label>

                                </form>
                            </div>
                            <div className="modal-footer">

                                <button onClick={openModal}  type="button" className="btn btn-info">
                                {loading && <i className="fa fa-cog fa-spin"></i>} Continue <i className="fa fa-long-arrow-right"></i></button>
                            
                            </div>
                        </div>

                    </LoadingOverlay>
                    
                </Modal>

            

        </SiteWide>
    );
}

export default DepositHistory;