import React, { Component, useEffect } from "react";
import {Router, Route, Switch, HashRouter} from "react-router-dom";

import UnverifiedMailRoute from './utility/UnverifiedMailRoute';
import PrivateRoute from './utility/PrivateRoute';
import PublicRoute from './utility/PublicRoute';


import history from "./History";
import Home from "./components/Home";
import Faq from "./components/Faq";
import FX from "./components/FX";


import About from "./components/About";
import AllPages from "./components/AllPages";
import BillPay from "./components/BillPay";
import Blog from "./components/Blog";
import BlogDetails from "./components/BlogDetails";
import Carts from "./components/Carts";
import Components from "./components/Components";
import ComponentsAccordion from "./components/ComponentsAccordion";
import ComponentsAlerts from "./components/ComponentsAlerts";
import ComponentsButtons from "./components/ComponentsButtons";
import ComponentsModals from "./components/ComponentsModals";
import ComponentsNotifications from "./components/ComponentsNotifications";
import ComponentsProgress from "./components/ComponentsProgress";
import ComponentsTabs from "./components/ComponentsTabs";
// import ComponentsTooltip from "./components/ComponentsTooltip";
import Contact from "./components/Contact";
import Notification from "./components/Notification";
import Saving from "./components/Saving";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import VerifyEmail from './components/VerifyEmail';
import ForgetPassword from './components/ForgetPassword';


import Transaction from "./components/Transaction";
import TransactionDetails from "./components/TransactionDetails";
import UserSetting from "./components/UserSetting";
import Wallet from "./components/Wallet";
import ChangePin from "./components/ChangePin";

import Trade from "./components/Trade";
import TradeHistory from "./components/Trade-history";
import Bot from "./components/Bot";
import Spinnard from "./components/Spinnard";


import Referrals from "./components/Referrals";


import DepositHistory from "./components/Deposit-history";
import WithdrawalHistory from "./components/Withdrawal-history";

import Deposit from "./components/Deposits/New";
import Withdraw from "./components/Withdraw/New";


import UserVerification from "./components/UserVerification";
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from './redux/actions';
import { userSelect, allRemoteSettingsSelect } from './redux/selectors/index';


    


function Routes(){

    
    const dispatch = useDispatch();
    const userData = useSelector(userSelect);


    useEffect(() => {
        dispatch(authActions.onRefreshSystemData("system/refresh", 10, {}, response => {}))
    }, [userData?.email])


    return (
        <Router history={history}>
            <HashRouter basename="/">
                <Switch>
                    <Route exact path={'/about'} render={(props) => (<About {...props} />)} />
                    <Route exact path={'/all-pages'} render={(props) => (<AllPages {...props} />)} />
                    <Route exact path={'/bill-pay'} render={(props) => (<BillPay {...props} />)} />
                    <Route exact path={'/blog'} render={(props) => (<Blog {...props} />)} />
                    <Route exact path={'/blog-details'} render={(props) => (<BlogDetails {...props} />)} />
                    <Route exact path={'/carts'} render={(props) => (<Carts {...props} />)} />
                    <Route exact path={'/components'} render={(props) => (<Components {...props} />)} />
                    <Route exact path={'/components-accordion'} render={(props) => (<ComponentsAccordion {...props} />)} />
                    <Route exact path={'/components-alerts'} render={(props) => (<ComponentsAlerts {...props} />)} />
                    <Route exact path={'/components-buttons'} render={(props) => (<ComponentsButtons {...props} />)} />
                    <Route exact path={'/components-modal'} render={(props) => (<ComponentsModals {...props} />)} />
                    <Route exact path={'/components-notifications'} render={(props) => (<ComponentsNotifications {...props} />)} />
                    <Route exact path={'/components-progress'} render={(props) => (<ComponentsProgress {...props} />)} />
                    <Route exact path={'/components-tabs'} render={(props) => (<ComponentsTabs {...props} />)} />
                    {/*<Route exact path={'/components-tooltip'} render={(props) => (<ComponentsTooltip {...props} />)} />*/}
                    <Route exact path={'/contact'} render={(props) => (<Contact {...props} />)} />
                    <Route exact path={'/notification'} render={(props) => (<Notification {...props} />)} />
                    <Route exact path={'/saving'} render={(props) => (<Saving {...props} />)} />
                    

                    <Route exact path={'/'} component={Home} />
                    <Route exact path={'/news'} component={Faq} />
                    <PrivateRoute exact path={'/fx'}  component={(props) => (<FX {...props} />)} />

                    <PublicRoute exact path={'/signin'} component={Signin} />
                    <PublicRoute exact path={'/signup'} component={Signup} />
                    <PublicRoute exact path={'/reset-password'} component={ForgetPassword} />


                    <UnverifiedMailRoute exact path={'/verify-email'} component={VerifyEmail} />
                    <UnverifiedMailRoute exact path={'/user-verification'} component={UserVerification} />

                    <Route exact path={'/transaction'} render={(props) => (<Transaction {...props} />)} />
                    <Route exact path={'/transaction-details'} render={(props) => (<TransactionDetails {...props} />)} />
                    <PrivateRoute exact path={'/user-setting'} component={(props) => (<UserSetting {...props} />)} />
                    <PrivateRoute exact path={'/wallet'} component={(props) => (<Wallet {...props} />)} />
                    <PrivateRoute exact path={'/new-pin'} component={ChangePin} />

                    <PrivateRoute exact path={'/trade'} component={Trade} />
                    <PrivateRoute exact path={'/Trade-history'} component={(props) => (<TradeHistory {...props} />)} />
                    <PrivateRoute exact path={'/bot'} component={(props) => (<Bot {...props} />)} />
                    <PrivateRoute exact path={'/wheel-of-fortune'} component={Spinnard} />

                    <PrivateRoute exact path={'/Deposit-history'} component={(props) => (<DepositHistory {...props} />)} />
                    <PrivateRoute exact path={'/withdrawal-history'} component={(props) => (<WithdrawalHistory {...props} />)} />

                    <PrivateRoute exact path={'/deposit'} component={Deposit} />
                    <PrivateRoute exact path={'/withdraw'} component={Withdraw} />

                    <PrivateRoute exact path={'/team'} component={(props) => (<Referrals {...props} />)} />


                </Switch>
            </HashRouter>
        </Router>
    )

}

export default Routes;
