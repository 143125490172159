import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {useHistory, useLocation} from 'react-router-dom';

import APIRequests from '../utility/APIRequests';
import {staticCountries} from '../utility/countries';
import { setUserSession, setUserFinance, setUserToken } from '../utility/CommonAuth';
import Helper1, {notify} from '../utility/Helper1';
import {Alert} from "../alertServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {authActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
const bacggroundImage = require('../images/home-2.jpg');

let referralToUse = localStorage?.getItem('ref') != 'null'?localStorage?.getItem('ref'):'';

function Signup() {


    const [username, setUsername]                             = useState('');
    const [email, setEmail]                             = useState('');
    const [password, setPassword]                       = useState('');
    const [loading, changeLoading]                      = useState(false);
    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const [flashCounter, setFlashCounter]               = useState(0);
    const [showPassword, setShowPassword]               = useState(false);
    const [pin, setPin]               = useState('');
    const [showPin, setShowPin]               = useState(false);

    const [country, setCountry]             = useState(0); //'156'
    const [phone, setPhone]                 = useState('');
    const [phoneCode, setPhoneCode]           = useState('...'); //'+234'
    const [referral, setReferrals]           = useState(referralToUse); //'+234'


    const search = useLocation().search;
    const referalLink = new URLSearchParams(search).get("ref");

    

    const history                                       = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if((referalLink != '') && (referalLink!= null) && (referalLink!='null')){
            localStorage.setItem('ref', referalLink);
            // referralToUse = referalLink;
            setReferrals(referalLink)
        }
    }, [])

    const doRegister = async (e) => {


        e.preventDefault();
        setFullPageLoading(true);
        
        dispatch(authActions.onSignUp("account/register", {pin, username, email, password, country, phone, referral}, response => {

            if(response.code != 0){

                localStorage.removeItem('ref');
                
                // if(response.code == 1){
                    history.replace('/user-setting');
                // }else if(response.code == 2){
                //     history.replace('/user-setting');
                // }
                
            }else{
                notify(response.msg, response.code)
            }

            setFullPageLoading(false);
        
        }),
        );


    }


  const changeCountry = (countryId) =>{
    let selectedCountry = staticCountries.filter(e => e.id == countryId) 
    setCountry(countryId);
    let countryPhoneCode = selectedCountry?.[0]?.phonecode;
    setPhoneCode(countryPhoneCode);
    
  }



    return (
        <SiteWide title="Sign up">
            <Section loading={fullPageLoading} title={'Sign up'} home={<WebLink to="/signin">Sign in</WebLink>}>
                

                <div className="signin-area mg-bottom-35">
                    <div className="container">
                        <form className="contact-form-inner">
                            


                            <label className="single-input-wrap">
                                <span className="text-white">Username*</span>
                                <input className="auth-input" onChange={e => setUsername(e.target.value) } placeholder="Username" required type="text" />
                            </label><label className="single-input-wrap">


                            <span className="text-white">Email Address*</span>
                                <input className="auth-input" onChange={e => setEmail(e.target.value) } placeholder="Email" required type="email" />
                            </label>


                        
                            <label className="single-input-wrap" style={{display: 'relative'}}>
                                <span className="text-white">Password*</span>
                                <div className="form-group input-group">
                                    <input type={ (showPassword) ? 'text' : 'password'} className="auth-input form-control" onChange={e => setPassword(e.target.value) } required placeholder="Password" />
                                    <div className="input-group-append" style={{position: 'absolute', right: 2, top: 4, backgroundColor: 'transparent !important', zIndex: 5}}>
                                        <span onClick={() => setShowPassword(!showPassword)} className="input-group-text cursor-pointer no-background" id="basic-addon1">
                                            <i className={(showPassword) ? 'fa fa-eye-slash' : 'fa fa-eye'}></i> 
                                        </span>
                                    </div>
                                </div>
                            </label>


                            <label className="single-input-wrap" style={{display: 'relative'}}>
                                <span className="text-white">Country*</span>
                                <select style={{height: 45}} value={country} onChange={e => changeCountry(e.target.value) } required=""  className="auth-input form-control">
                                    <option hidden value="">Select country</option>
                                    {
                                        staticCountries.map((item, index) => 
                                            <option key={index} value={item.id}>{item.nicename}</option>
                                        )
                                    }
                                </select>
                            </label>

                            <label className="single-input-wrap" style={{display: 'relative'}}>
                                <span className="text-white">Phone*</span>
                                <div className="form-group input-group">
                                    <input type='text' style={{paddingLeft: 80}} className="auth-input form-control" onChange={e => setPhone(e.target.value) } required placeholder="Phone" />
                                    <div className="input-group-prepend" style={{position: 'absolute', width: 70, textAlign: 'center !important', left: 2, top: 4, zIndex: 99999999, borderRight: '1px solid #cecece'}}>
                                        <span style={{margin: '0 auto'}} onClick={() => setShowPassword(!showPassword)} className="input-group-text cursor-pointer no-background" id="basic-addon1">
                                            {phoneCode}
                                        </span>
                                    </div>
                                </div>
                            </label>



                            <label className="single-input-wrap" style={{display: 'relative'}}>
                                <span className="text-white">Security PIN*</span>

                                <div className="form-group input-group">
                                    <input type={ (showPin) ? 'text' : 'password'} className="auth-input" maxLength="4" onChange={e => setPin(e.target.value) } placeholder="Security Pin" required />
                                
                                    <div className="input-group-append" style={{position: 'absolute', right: 2, top: 4, backgroundColor: 'transparent !important', zIndex: 5}}>
                                        <span onClick={() => setShowPin(!showPin)} className="input-group-text cursor-pointer no-background" id="basic-addon1">
                                            <i className={(showPin) ? 'fa fa-eye-slash' : 'fa fa-eye'}></i> 
                                        </span>
                                    </div>
                                </div>

                            </label>


                            <label className="single-input-wrap">
                                <span className="text-white">Invitation Code (optional)</span>
                                <input defaultValue={referral} className="auth-input" onChange={e => setReferrals(e.target.value) } placeholder="Invitation code" type="text" required />
                            </label>



                            {/*<div className="sngle-checkbox-wrap">
                                <input value="1" id="termsPlace" type="checkbox" /> <label for="termsPlace" className="text-white cursor-pointer"> Accept terms & condition</label>
                            </div>*/}
                            <button onClick={doRegister} className="btn btn-danger btn-block auth-input">Register</button>
                        </form>
                    </div>
                </div>
            </Section>
        </SiteWide>
    );

}

export default Signup;