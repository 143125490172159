import React, {Component} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import AutoNotification from "./AutoNotification";
import { ToastContainer } from 'react-toastify';

import LoadingOverlay from 'react-loading-overlay';

function Section(props){



    return (
        <LoadingOverlay
            active={props.loading}
            spinner
            text='Loading...'
        >
            <section className="main-section" style={props.sectionstyle}>
                <ToastContainer />
                {!props.hideHeader && <Header back={props.back} title={props.title} rightTopMenu={props.rightTopMenu} home={props.home} searchPopup={props.searchPopup} />}
                {props.children}

                <Footer />

            </section>
        </LoadingOverlay>
    );

}

export default Section;