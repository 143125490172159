import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {useHistory} from 'react-router-dom';
import APIRequests from '../utility/APIRequests';
import Helper1, {notify} from '../utility/Helper1';
import {Alert} from "../alertServices";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {authActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
// import { auth } from '../redux/actions/auth';
import { userSelect } from '../redux/selectors/index';
import MobileView from './customComponents/MobileView';


const bacggroundImage = require('../images/home-2.jpg');

function Signin() {

    const dispatch = useDispatch();
    const users = useSelector(userSelect);

    const [email, setEmail]                             = useState();
    const [password, setPassword]                       = useState();
    const [loading, changeLoading]                      = useState(false);
    const [notificationMessage, setNotificationMessage] = useState();
    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const [flashCounter, setFlashCounter]               = useState(0);
    const history                                       = useHistory();


    const doLogin = async (e) => {

        e.preventDefault();
        setFullPageLoading(true);
        
        dispatch(authActions.onLogin("account/login", {username:email, password:password}, response => {

            if(response.code != 0){

                if(response.code == 1){
                    history.replace('/user-setting');
                }else if(response.code == 2){
                    history.replace('/verify-email');
                }
            }else{
                notify(response.msg, response.code)
            }

            setFullPageLoading(false);
        
        }),
    );

        
    };

    useEffect(() => {
        document.title = (new Helper1).title("Account login");
      }, []);



    return (<SiteWide title="Sign in">
        <Section loading={fullPageLoading} allNotification={false} searchPopup={true} title={'Sign in'} home={<WebLink to="/signup">Sign up</WebLink>}>
            

            <div className="signin-area">
                <div className="container">
                    <form className="contact-form-inner auth-box" style={{}}>
                        <label className="single-input-wrap">
                            <span className="text-white">Username</span>
                            <input placeholder="Username" onChange = {e => setEmail(e.target.value) } required="" className="auth-input" type="text" />
                        </label>
                        <label className="single-input-wrap">
                            <span className="text-white">Password</span>
                            <input placeholder="Password" onChange={e => setPassword(e.target.value) } required="" className="auth-input" type="password" />
                        </label>
                        
                        <WebLink className="forgot-btn text-white" to={'/reset-password'}>Forgot password?</WebLink>

                        <button onClick={doLogin} className="btn btn-danger btn-block auth-input" to={'/signin'}>Login</button>
                        
                        <WebLink className="forgot-btn text-white" to={'/signup'}>Register <i className="fa fa-long-arrow-right"></i></WebLink>

                    </form>
                </div>
            </div>


            
        </Section>
        </SiteWide>
    );

}

export default Signin;