import React, {Component, useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {WebLink, SiteWide} from "./customComponents";
import Section from "./layouts/Section";

import Slider from "react-slick";
import {Images} from "./customComponents";
import Colors from '../utility/Colors';

import {fxActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allFXSelect, userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import {capitalizeFirstLetter, money, niceDateTime1} from '../utility/Helper1';
import { BiTimeFive, BiBot, BiRefresh, BiBrushAlt, BiBrush } from "react-icons/bi";
import { CiRedo } from "react-icons/ci";

    

function TradeHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loading, setloading]   = useState(false);

    const dispatch = useDispatch();
    const loadFxData = useSelector(allFXSelect);


    const loadAllFXes = () => {

        setloading(true)

        dispatch(fxActions.onGetFxHead("fx/load_all/1", {}, response => {
            if(response.code == 1){
                setloading(false)
            }
        }))

    }


    useEffect(() => {
        loadAllFXes()
    }, [])


    const writeDownOrUp = (zeroOrOne) => {

        if(String(zeroOrOne) == '0'){
            return 'Small'
        }else if(String(zeroOrOne) == '1'){
            return 'Big'
        }else{
            return ' is Loading...'
        }

    }


    return (

        <SiteWide title={"Trades History"}>
            <Section back="/trade" loading={loading} title={'Trades History'}
                >

                <div className="goalottom-36">
                    <div className="containe" style={{paddingRight: 20, paddingLeft: 20}}>
                        
                        <div className="" style={{}}>
                            {loadFxData?.fxTradehistory?.slice(0, 100)?.map((item, index) => {
                                   

                                    return <div className="" style={{marginBottom: 12, padding: 15, borderRadius: 8, backgroundColor: Colors.all.greyBgHolder_1}}>

                                        <div style={{paddingRight: 0, paddingTop: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            
                                            <div style={{marginLeft: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <BiTimeFive /> 
                                                <small style={{marginLeft: 5}}>{niceDateTime1(item.timePlayed)}</small>

                                                {item.releasedEarning == 0 && <div style={{display: 'flex', alignItems: 'center'}} className="ext-white">
                                                    <span className="bebas-text" style={{marginLeft: 10}}>Trade ongoing</span>
                                                </div>}

                                                {item.amountRefunded > 0 && <div style={{display: 'flex', alignItems: 'center'}} className="ext-white">
                                                    <span className="bebas-text" style={{marginLeft: 10, color: 'green'}}>{money(item.amountRefunded, item.type)} Refunded</span>
                                                </div>}
                                            </div>

                                            {item?.thisStage !='' && <div style={{padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                STAGE <div style={{width: 20, height: 20, borderRadius: 30, marginLeft: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.all.bluey, color: '#fff', fontWeight: 900,}} className="btn-rond bn tn-danger">{item.thisStage}</div>
                                            </div>}

                                        </div>

                                        <div className="" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        
                                            <div className="" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                
                                                <div className="thumb" style={{backgroundColor: 'transparent', border: 0}}>
                                                    <Images alt={item.cryptoSelected} source={require(`../images/${item.cryptoSelected}.png`)} width={50} />
                                                </div>

                                                <div className="" style={{marginLeft: 10,}}>
                                                    <div className="text-white bebas-text" style={{fontWeight: 900, marginRight: 10}}>
                                                        {item.fxId}
                                                        <span style={{marginLeft: 5}} className="badge badge-success">{item.minutes} Min</span>
                                                    </div>

                                                    <small className="text-white">
                                                        Traded {writeDownOrUp(item?.downOrUp)} <BiBrushAlt />{" "}{" "}
                                                        Result {writeDownOrUp(item?.result)} <BiBrush />{" "}{" "}
                                                        {item.isBotTrade != 0 && <span> Bot Used <BiBot /></span>}

                                                    </small>

                                                </div>

                                            </div>

                                            <div className="text-right" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                                <h5 className="text-white bebas-text">{money(item.amount, item.type)}</h5>
                                                {item.releasedEarning == 1 && <span className="text-white bebas-text">+ {money(item.walkawayAmount, item.type)}</span>}
                                                {item.releasedEarning == 0 && <div style={{display: 'flex', alignItems: 'center'}} className="text-white">
                                                    <iframe style={{marginLeft: 5}} width={20} height={20} src="https://embed.lottiefiles.com/animation/111560"></iframe>
                                                </div>}
                                            </div>
                                        </div>

                                    </div>
                                }
                            )}
                        </div>

                        
                        {loadFxData?.fxTradehistory?.length == 0 && !loading && <div>
                            <h5 className="text-warning">No history found.</h5>
                        </div>}


                    </div>
                </div>


                <span onClick={loadAllFXes} className="float cursor-pointer">
                    <CiRedo className={loading?'fa-spin':''} size={40} />
                </span>

            </Section>
        </SiteWide>
    );
}

export default TradeHistory;