import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from "../redux/store";



class Helper1{

  title = (title) => {
    return title + " :: Yellow Pages Nigeria";
  }

  status = () => {
    return {0:'Awaiting approval', 1:'Active', 2:'Suspended'};
  }

}


export const notify = (msg, code) => {
  if(code == 1){
      return toast.success(msg);
  }else if(code == 2){
    return toast.info(msg);
  }else{
    return toast.error(msg);
  }
}


export const capitalizeFirstLetter =(str) => {
  if(!str){ return ''; }
  return str.charAt(0).toUpperCase() + str.slice(1)
}


export const niceDate = (paramObj) => {

  // let defString = String(paramObj);
  // let nuggaRaw = (defString.length > 20)?paramObj:(paramObj*1000);
  const dateObj = new Date(paramObj);
  return dateObj.getFullYear() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getDate();
}


export const niceDateTime1 = (dateString) => {
  
  const date = new Date(dateString);
  return date.toLocaleString();

}

const getMother = () => store.getState()?.finance?.homescreenstat; //state.

export const money = (myAmount, currency, addObj={}) => {

  const motherObj = getMother()?.filter((item, index) => item.motherType === currency)
  const selectedMother = motherObj?.[0] ?? '';

  let fraction = addObj?.decimals ?? selectedMother?.decimals ?? 3

  var nf = new Intl.NumberFormat("en-US", { maximumFractionDigits: fraction, minimumFractionDigits: fraction } );
  let syn = ((selectedMother?.useRupeeUserEnd ?? '') == 1)?(selectedMother?.symbol2 ?? ''):selectedMother?.symbol ?? '';
  // let syn = '';

  if(addObj.symbolOnly){ return syn; }
  
  let newAmount = (((selectedMother?.useRupeeUserEnd ?? '') == 1) &&  //if admin wants rupee conversion, yes
                  ((addObj?.convertToRupee ?? true)) &&
                  (!addObj?.alreadyRupeeSoDoNotConvertToRupee))?(Number(myAmount) * selectedMother?.rupeeToOneDollar):myAmount

  let amount = nf.format(newAmount);

  if(addObj?.amountOnly){ return amount }

  if((selectedMother?.symbollocation == 0) || (selectedMother?.symbollocation == 'left')){
    return syn + amount
  }else if((selectedMother?.symbollocation == 1) || (selectedMother?.symbollocation == 'right')){
    return amount + syn
  }

  return `${amount}`
  

}



export const moneyStatus = () => {
  return ['Pending', 'Processing', 'Confirmed', 'Cancelled'];
}


export const usefulColors = (code) => {
    let array = ['danger', 'success', 'info', 'warning'];
    return array[code] ?? 'warning';
}




export default Helper1;