import * as actionTypes from "./actionTypes";

export const onLogin = (endPointToCall, params, callback = () => {}) => {
  return {
    endPointToCall,
    type: actionTypes.LOGIN,
    params,
    callback,
  };
};


export const onSignUp = (endPointToCall, params, callback = () => {}) => {
  return {
    endPointToCall,
    type: actionTypes.SIGN_UP,
    params,
    callback,
  };
};


// export const userLevelGetRequest = (endPointToCall, callback = () => {}) => {
//   return {
//     endPointToCall,
//     type: actionTypes.SIGN_UP,
//     callback,
//   };
// };


export const onRefreshSystemData = (endPointToCall, enforceLogout, params, callback = () => {}) => {
  return {
    endPointToCall,
    type: actionTypes.RELOAD_SYSTEM_DATA,
    params,
    enforceLogout,
    callback,
  };
};


export const onLogout = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};
