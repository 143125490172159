import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';


function* onGetFxHead(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall, action.params);

    if (response.code == 1) {

      let token = response.access_token;

      // response.data.user.token = response.access_token;

      // yield put({ type: actionTypes.UPDATE_TOKEN, token });
      
      if(response?.data){
        yield put({ type: actionTypes.SAVE_FX_HEAD, fx: response.data });
      }

      // yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user }); //troblesome. No time

      if(response?.data?.user?.finance){
        yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      }

      if(response?.data?.user?.settings){
        yield put({ type: actionTypes.SAVE_REMOTE_SITE_SETTINGS, settings: response.data.settings }); 
      }
      
    }

    return action?.callback?.(response);
  } catch (error) {
    return action?.callback?.(error.response ?? error.message);
  }
}





function* watchGetFxHead() {
  yield takeEvery(actionTypes.FETCH_FX_HEAD, onGetFxHead);
}


export default function* allFxSagas() {
  yield all([watchGetFxHead()]);
}
