import React, {useState, useEffect} from "react";
import TradeViewChart from 'react-crypto-chart';



export const Chart = React.memo((props) => {

  return (
    <div style={{maxHeight: '100vh', flex:1,
                          zIndex: 0,}}>
        <TradeViewChart
                        containerStyle={{height: '62vh', minHeight: '100%', minWidth: '400px', zIndex: 0,}}


                        chartLayout={{
                            layout: {
                                backgroundColor: "#000",
                                textColor: "#f70",
                            },
                           grid: {
                                vertLines: {
                                color: "#000",
                                },
                                horzLines: {
                                color: "#000",
                                },
                            },
                            priceScale: {
                                borderColor: "#485c7b",
                            },
                            timeScale: {
                                borderColor: "#485c7b",
                                timeVisible: true,
                                secondsVisible: true,
                            },
                        }}

                        pair={props.pair ?? "BTCUSDT"}
                    />
    </div>
  );

});