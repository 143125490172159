import React, {useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import Helper1, {notify} from '../utility/Helper1';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Modal from 'react-modal';

    

function UserSetting(){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);

    const [walletModal, setWalletModal]   = useState(false);
    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [password, setPassword]       = useState('');
    const [pin, setPin]       = useState('');



    const [wallet, setWallet]       = useState(userData?.trc20tetherwallet ?? '');


    const dispatch = useDispatch();

    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }

    const doPasswordChange = async (e) => {

        if(password == ''){
            notify('Please input new password', 0);
            return false;
        }
        setloading(true);
        e.preventDefault();
        dispatch(authActions.onLogin("account/change_password", {password, withdrawalPin:pin}, response => {
            if(response.code == 1){
                setPassword('');
            }
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

        
    }

    const doAddressChange = async (e) => {
        setloading(true);
        e.preventDefault();
        dispatch(authActions.onLogin("account/account_change", {wallet}, response => {
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

        
    }

    const saveBTNJSX = (callback) => (
        <>
            {/*<button type="button" className="btn btn-shadow" data-dismiss="modal">Close</button>*/}
            <button onClick={callback}  type="button" disabled={loading} className="btn btn-primary">
            {loading && <i className="fa fa-cog fa-spin"></i>} Change</button>
        </>
        )



    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    return (
        <SiteWide title={`${userData?.username} Account details`}>
            <Section loading={loading} allNotification={false} searchPopup={true} title={`${userData?.username} Account details`}>

                <div className="transaction-details pd-t-35" style={{backgroundColor: 'transparent', overflowY: 'auto'}}>
                    <div className="container">
                        
                        <ul className="transaction-details-inner" style={{backgroundColor: 'transparent', overflowY: 'auto'}}>
                            <li>
                                <CopyToClipboard text={site_settings?.site_settings?.site_url+'/#/signup?ref='+userData?.myrefid}
                                            onCopy={() => notify('Invitation link copied', 1)}>
                                    <span className="cursor-pointer">
                                        <span className="float-left">Invitation link</span>
                                        <span className="float-right">{userData?.myrefid} <i className="fa fa-copy" /></span>
                                    </span>
                                </CopyToClipboard>
                            </li>

                            <li>
                                <WebLink to={'/trade-history'}>
                                    <span className="float-left">My Trades</span>
                                    <span className="float-right"><i className="la la-angle-right" /></span>
                                </WebLink>
                            </li>

                            <li>
                                <WebLink to={'/deposit-history'}>
                                    <span className="float-left">Deposits</span>
                                    <span className="float-right"><i className="la la-angle-right" /></span>
                                </WebLink>
                            </li>


                            <li>
                                <WebLink to={'/withdrawal-history'}>
                                    <span className="float-left">Withdrawals</span>
                                    <span className="float-right"><i className="la la-angle-right" /></span>
                                </WebLink>
                            </li>
                           
                            {/*<li>
                                <WebLink to={'/user-setting'}>
                                    <span className="float-left">Rebate</span>
                                    <span className="float-right"><i className="la la-angle-right" /></span>
                                </WebLink>
                            </li>*/}
                            <li>
                                <WebLink to={'/team'}>
                                    <span className="float-left">Team</span>
                                    <span className="float-right"><i className="la la-angle-right" /></span>
                                </WebLink>
                            </li>

                            {/*<li>
                                <WebLink to={'/about'}>
                                    <span className="float-left">About</span>
                                    <span className="float-right"><i className="la la-angle-right" /></span>
                                </WebLink>
                            </li>*/}
                            

                            <li>
                                <WebLink data-toggle="modal" fake-data-target="#modal-centered" onClick={() => setPasswordModal(true)}>
                                    <span className="float-left">Change password</span>
                                    <span className="float-right"><i className="la la-angle-right" /></span>
                                </WebLink>
                            </li>

                            <li>
                                <WebLink to="/new-pin">
                                    <span className="float-left">Update PIN</span>
                                    <span className="float-right"><i className="la la-angle-right" /></span>
                                </WebLink>
                            </li>


                            <li>
                                <span onClick={doLogOutNowNow} className="cursor-pointer" to={'/user-setting'}>
                                    <span className="float-left">Sign out</span>
                                    <span className="float-right"><i className="fa fa-sign-out" /></span>
                                </span>
                            </li>

                            <li></li>
                        </ul>
                    </div>
                </div>

                

                <Modal
                    isOpen={walletModal}
                    onRequestClose={() => setWalletModal(false)}
                    contentLabel="Example Modal"
                    style={customStyles}
                  >
                    <div className="mde">
                        <div className="modal-content bg-warnig">
                            <div className="modal-header">
                                <h4 className="modal-title">New TRC20 Wallet Address</h4>
                                <button onClick={() => setWalletModal(false)} type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">close</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{padding: '0 !important'}}>
                                <form className="contact-f-box" style={{}}>
                                    <label className="single-input-wrap">
                                        <span className="text-dark">TRC20 Wallet Address</span>
                                        <input value={wallet} placeholder="New address" onChange = {e => setWallet(e.target.value) } required="" className="auth-input" type="text" />
                                    </label>
                                    
                                    
                                </form>
                            </div>
                            <div className="modal-footer">
                                {saveBTNJSX(doAddressChange)}
                            </div>
                        </div>
                    </div>
                </Modal>




                <Modal
                    isOpen={passwordModal}
                    onRequestClose={() => setPasswordModal(false)}
                    contentLabel="Example Modal"
                    style={customStyles}
                  >
                    <div>
                        <div className="modal-content bg-warnig">
                            <div className="modal-header">
                                <h4 className="modal-title">Change Password</h4>
                                <button onClick={() => setPasswordModal(false)} type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">close</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{padding: '0 !important'}}>
                                <form className="contact-f-box" style={{}}>
                                    <label className="single-input-wrap">
                                        <span className="text-dark">Password</span>
                                        <input placeholder="New setPassword" onChange = {e => setPassword(e.target.value) } required="" className="auth-input" type="password" />
                                    </label>

                                    <label className="single-input-wrap">
                                        <span className="text-dark">Pin</span>
                                        <input placeholder="Account PIN" onChange = {e => setPin(e.target.value) } required="" className="auth-input" type="password" />
                                    </label>
                                    
                                    
                                </form>
                            </div>
                            <div className="modal-footer">
                                {saveBTNJSX(doPasswordChange)} 
                            </div>
                        </div>
                    </div>
                </Modal>


            </Section>
        </SiteWide>
    );

}

export default UserSetting;