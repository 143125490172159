import React, {Component, useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {WebLink, SiteWide} from "./customComponents";
import Section from "./layouts/Section";

import Slider from "react-slick";
import {Images} from "./customComponents";
import Colors from '../utility/Colors';

import {fxActions, authActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allFXSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {capitalizeFirstLetter, money, niceDateTime1, notify} from '../utility/Helper1';
import { BiTimeFive, BiBot, BiRefresh, BiBrushAlt, BiBrush } from "react-icons/bi";
import { CiRedo } from "react-icons/ci";



function Bot() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loading, setloading]   = useState(false);

    const [showTheTradingCapital, setShowTheTradingCapital]   = useState(false);
    const [tradingBotCapital, setTradingBotCapital]   = useState('');

    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();



    const buyABot = () => {
        
        setloading(true);

        dispatch(authActions.onLogin(`buy/trading/bot`, {capital: tradingBotCapital}, response => {
                
                setloading(false)

                if(response.code == 1){


                }
                
                notify(response.msg, response.code);

            }),
        )
    }

    const onlyUpdateTradingCapital = () => {
        
        setloading(true);

        dispatch(authActions.onLogin(`update/trading/bot/capital`, {capital: tradingBotCapital}, response => {
                
                setloading(false)

                if(response.code == 1){


                }
                
                notify(response.msg, response.code);

            }),
        )
    }



    const toggleBotStatus = () => {
        
        setloading(true);

        dispatch(authActions.onLogin(`toggle/trading/bot`, {}, response => {
                
                setloading(false)


                if(response.code == 1){

                }
                
                notify(response.msg, response.code);

            }),
        )
    }





    const reloadUserInfo = () => {

        dispatch(authActions.onLogin(`account/reload/user/info`, {}, response => {

                if(response.code == 1){

                }
                // else{
                //     notify(response.msg, response.code);
                // }
                
            }),
        )


    }

    useEffect(() => {
        reloadUserInfo()
    }, [])



    const toggleTradingCapital = () => {
        setShowTheTradingCapital(el => !el)
    }
    
    const submitTradingCapitallace = () => {
        if(userData.botActive == 0){
            buyABot()
        }else{
            onlyUpdateTradingCapital()
        }
    }


    const costOfBot = money(finance?.[0]?.tradingBotAmount, finance?.[0]?.motherType, {decimals: 0})

    return (

        <SiteWide title={"Bot Management"}>
            <Section back="/" loading={loading} title={'Bot Management'}
                >

                <div className="">
                    
                    <div className="text-center">
                        <Images alt={'bot Management'} source={require(`../images/bot.png`)} width={50} />
                    </div>

                    <div className="row">
                        <div className="col-2 col-sm-2"></div>

                        <div className="col-8 col-sm-8" style={{borderRadius: 10, backgroundColor: Colors.all.greyBgHolder_1, marginTop: 20}}>

                            <div className="text-center" style={{padding: 15}}>

                                <h3 className="text-white bebas-text">Bot Management</h3>

                                <p className="text-white">
                                    {site_settings?.content_settings?.[0]?.[userData.botActive == 0?'con1':'con2']}
                                </p>

                                {userData.botActive == 0 && <h1 style={{fontSize: 50, color: '#fff'}}>
                                    {costOfBot}
                                </h1>}

                                <div className="text-center" 
                                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                                    {userData.botActive == 0 && !showTheTradingCapital &&  <div onClick={() => window.confirm(`${costOfBot} would be charged from your balance. Are you sure you want to continue?`) && toggleTradingCapital()} className="cursor-pointer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: '120px', padding: 5, backgroundColor: '#fff', borderRadius: 30,}}>
                                        <Images alt={'bot Management'} source={require(`../images/tron.png`)} width={30} />
                                        <span style={{flex: 1}} className="bebas-text text-dark">Buy Now</span>
                                    </div>}

                                    <div className="text-center" 
                                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                                        {userData.botActive == 1 && <div onClick={() => window.confirm(`Would you like to continue with bot disable action?`) && toggleBotStatus()} className="cursor-pointer bg-danger" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: '120px', padding: 5, borderRadius: 30,}}>
                                            <Images alt={'bot Management'} source={require(`../images/tron.png`)} width={30} />
                                            <span style={{flex: 1}} className="bebas-text text-white">Disable Bot</span>
                                        </div>}


                                        {userData.botActive == 2 && <div onClick={() => window.confirm(`Would you like to enable bot?`) && toggleBotStatus()} className="cursor-pointer bg-info" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: '120px', padding: 5, borderRadius: 30,}}>
                                            <Images alt={'bot Management'} source={require(`../images/tron.png`)} width={30} />
                                            <span style={{flex: 1}} className="bebas-text text-white">Enable Bot</span>
                                        </div>}


                                        {userData.botActive == 1 && <div onClick={toggleTradingCapital} className="cursor-pointer bg-info" style={{marginLeft: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: '120px', padding: 5, borderRadius: 30,}}>
                                            <Images alt={'update capital'} source={require(`../images/setting.png`)} width={30} />
                                            <span style={{flex: 1}} className="bebas-text text-white">Update Capital</span>
                                        </div>}

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>











                    {showTheTradingCapital && <div className="row">
                        <div className="col-2 col-sm-2"></div>

                        <div className="col-8 col-sm-8" style={{borderRadius: 10, backgroundColor: Colors.all.bluey, marginTop: 20}}>

                            <div className="text-center" style={{padding: 15}}>

                                <h3 className="text-white bebas-text">Specify Trading Capital</h3>

                                <p className="text-white">{site_settings?.content_settings?.[0]?.['con3']}</p>

                                <div className="text-center" 
                                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                                    <div className="cursor-pointer" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10, width: '180px', padding: 5, backgroundColor: '#fff', borderRadius: 30,}}>
                                        <input value={tradingBotCapital} onChange={(e) => setTradingBotCapital(e.target.value)}  placeholder={"Enter Capital"} style={{backgroundColor: '#fff', width: '100%', border: 'none'}}/>
                                        <span onClick={submitTradingCapitallace} style={{flex: 1}} className="bebas-text text-dark">Submit</span>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>}





                    {(userData.botError ?? '') != '' && userData.botActive == 1 && <div className="row">
                        <div className="col-2 col-sm-2"></div>

                        <div className="col-8 col-sm-8" style={{borderRadius: 10, backgroundColor: '#f70', marginTop: 20}}>

                            <div className="text-center" style={{padding: 15}}>

                                <h3 className="text-white bebas-text">Bot Error</h3>

                                <p className="text-white">{userData.botError}</p>

                            </div>

                        </div>
                    </div>}










                </div>

            </Section>
        </SiteWide>
    );
}

export default Bot;