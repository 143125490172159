import React, {Component, useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {WebLink, SiteWide} from "./customComponents";
import Section from "./layouts/Section";

import Slider from "react-slick";
import {Images} from "./customComponents";
import Colors from '../utility/Colors';

import {fxActions, authActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allFXSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {capitalizeFirstLetter, money, niceDateTime1, notify} from '../utility/Helper1';
import { BiTimeFive, BiBot, BiRefresh, BiBrushAlt, BiBrush } from "react-icons/bi";
import { CiRedo } from "react-icons/ci";

import WheelComponent from 'react-wheel-of-prizes'
// import 'react-wheel-of-prizes/dist/index.css'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

function Spinnard() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loading, setloading]   = useState(false);
    const finance = useSelector(homeScreenStatSelect);

    const [showTheTradingCapital, setShowTheTradingCapital]   = useState(false);
    const [tradingBotCapital, setTradingBotCapital]   = useState('');
    const [wonTheSpin, setWonTheSpin]   = useState(false);
    const [winningProbabilityIndex, setWinningProbabilityIndex]   = useState(0);
    const [changeWiningChanceAgain, setChangeWiningChanceAgain]   = useState(false);
    const [awardingWinning, setAwardingWinning]   = useState(false);

    const [userBalance, setUserBalance]   = useState(finance?.[0]?.balance >= site_settings.site_settings.spin_radius?.[0]['min']?finance?.[0]?.balance:0);

    const { width, height } = useWindowSize()



    const dispatch = useDispatch();  

    const MINIMUM_AMOUNT = 100
    const TRY_AGAIN = 'Try again'


    const formatAmountTemp = (amount) => {
        return money(amount, finance?.[0]?.motherType)
    }

    const basedOnBalance = () => {

        let multiplier = 0;

        site_settings.site_settings.spin_radius.forEach((item) => {

            if(userBalance >= item.min && userBalance <= item.max){
                multiplier = Number(item.percent)/100
            }

        })
        

        return multiplier

    }

    const amountToWin = formatAmountTemp(userBalance * basedOnBalance())
    const winningFigure = userData.spinAvailable > 0?amountToWin:TRY_AGAIN


    // spinningPossibilities is just a flex whereby users spin more than once to win what they are entitled to
    // This makes it look more real rather than spinning once and getting their gift item right away

    const spinningPossibilities = [
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            TRY_AGAIN,
            winningFigure,
            TRY_AGAIN
        ]



    const wheelValues = [
        {value: TRY_AGAIN},
        {value: (userBalance * 0.01)},
        {value: amountToWin},
        {value: (userBalance * 0.9)},
        {value: (userBalance * 0.4)},
        {value: TRY_AGAIN},
        {value: TRY_AGAIN},
        {value: (userBalance * 0.02)},
    ];


    const segments = wheelValues.map((item) => {
        if(item.value == 0){ return 'RECHARGE'}
        if(!isNaN(item.value)){ return formatAmountTemp(item.value)} //if Numeric
        return String(item.value)
    })
 

    const segColors = [
        '#EE4040',
        '#F0CF50',
        '#815CD1',
        '#3DA5E0',
        '#34A24F',
        '#F9AA1F',
        '#EC3F3F',
        '#FF9000'
    ]



    const callBackEndForWinning = () => {

        dispatch(authActions.onLogin(`spinnard/award/win`, {}, response => {

                if(response.code == 1){
                    setAwardingWinning(false)
                }else{
                    notify(response.msg, response.code);
                }
                
            }),
        )


    }


    const reloadUserInfo = () => {

        dispatch(authActions.onLogin(`account/reload/user/info`, {}, response => {

                if(response.code == 1){

                }
                // else{
                //     notify(response.msg, response.code);
                // }
                
            }),
        )


    }

    useEffect(() => {
        reloadUserInfo()
    }, [])


    useEffect(() => {

        if((spinningPossibilities[winningProbabilityIndex] == amountToWin) && (userData.spinAvailable > 0)){
            setWonTheSpin(true)
            setAwardingWinning(true)
            callBackEndForWinning()
        }else{
            let rawProb = Math.floor(Math.random() * spinningPossibilities.length)
            setWinningProbabilityIndex(rawProb)
        }

    }, [changeWiningChanceAgain])


    const icallFinisher = () => {
        setChangeWiningChanceAgain(el => !el)
    }


    const onFinished = (winner) => {

        icallFinisher()
        
    }


    const now = new Date();
    const timestamp = now.getTime();
    const key = `key-${timestamp}`;

    return (

        <SiteWide title={"Wheel of fortune"}>
            <Section back="/" loading={loading} title={'Wheel of fortune'}
                >

                <div className="" style={{display: 'flex', justifyContent: 'center'}}>
                    

                    <div className="row" style={{}}>
                        <div className="col-2 col-sm-2"></div>

                        <div className="col-8 col-sm-8 text-center" style={{height: 500, borderRadius: 10, marginTop: -180}}>



                                {!wonTheSpin && <WheelComponent

                                    segments={segments}
                                    segColors={segColors}
                                    onFinished={onFinished}
                                    primaryColor='#1866fb'
                                    contrastColor='white'
                                    buttonText='WIN'
                                    isOnlyOnce={false}
                                    size={160}
                                    upDuration={500}
                                    downDuration={1000}
                                    fontFamily='Arial'
                                    winningSegment={TRY_AGAIN}
                                />}



                        </div>



                    </div>


                </div>





                    <div className="row">
                        <div className="col-2 col-sm-2"></div>

                        <div className="col-8 col-sm-8" style={{borderRadius: 10, backgroundColor: Colors.all.bluey, marginTop: 20}}>

                            <div className="text-center" style={{padding: 15}}>

                                <h3 className="text-white bebas-text">RULES</h3>

                                <p className="text-white">{site_settings?.content_settings?.[0]?.['con4']}</p>

                                <div className="text-center" 
                                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                                    <div className="cursor-pointer" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10, width: '180px', padding: 5, backgroundColor: '#fff', borderRadius: 30,}}>
                                        <span style={{flex: 1}} className="bebas-text text-dark">Available Wins Today: {userData.spinAvailable ?? 0}</span>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    
                    {wonTheSpin && <><Confetti
                      width={width}
                      height={height}
                    />

                    <div style={{position: 'absolute', top: '30%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'transparent',

                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        border: 'none',
                        zIndex: 10}}>
                        
                        <Images style={{}} alt={'bot Management'} source={require(`../images/treasure.png`)} width={'50%'} />

                        <h1 style={{padding: 10}} className="bebas-text text-white">+{amountToWin}</h1>
                        {awardingWinning && <h3 style={{padding: 3}} className="bebas-text text-white">Crediting your balance... Please wait</h3>}
                        {!awardingWinning && <h3 style={{padding: 3}} className="bebas-text text-success">Account credited</h3>}


                    </div></>}

            </Section>
        </SiteWide>
    );
}

export default Spinnard;

