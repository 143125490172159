import React, {Component, useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {WebLink, SiteWide} from "./customComponents";
import Section from "./layouts/Section";

import Slider from "react-slick";
import {Images} from "./customComponents";
import Colors from '../utility/Colors';
import Helper1, {notify, money, niceDate} from '../utility/Helper1';

import {depositActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';

    

function DepositHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);
    const [dataHasChanged, setDataHasChanged]   = useState(false);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);

    const [enterAmountModal, setEnterAmountModal]   = useState(false);
    const [newDepositInfo, setNewDepositInfo]   = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);


    const loadAllDeposit = () => {

        setloading(true)
        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            if(response.code == 1){
                setloading(false)
                setDataHasChanged(!dataHasChanged)
            }
        }))

    }



    const deleteInv = (txn_id) => {

        setDeleting(true)
        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {
            if(response.code == 1){
                setDeleting(false)
                setDataHasChanged(!dataHasChanged)
                setNewDepositInfo([]);
                setIsOpen(false);
            }
        }))

    }


    useEffect(() => {
        loadAllDeposit()
    }, [])


    // useEffect(() => {

    //     let pendingDeposit = loadHistoryData?.filter((item, index) => item.status < 2)

    //     if(pendingDeposit?.length > 0){
    //         setIsOpen(true);
    //         setNewDepositInfo(pendingDeposit)
    //     }

    // }, [dataHasChanged])



    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };


    const openModal = () => {
        
        if((isNaN(amount)) || (amount == 0)){
            notify('Enter amount', 0);
            return;
        }

        setPaymentPlaceLoading(true);
        dispatch(depositActions.onCreateDeposit({amount, currency: finance?.[0]?.country}, "deposit/new", response => {
            if(response.code == 1){
                
                setDataHasChanged(!dataHasChanged)
                setEnterAmountModal(false);

            }

            notify(response.msg, response.code)
            setPaymentPlaceLoading(false)
        }))
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const status = ['Pending', 'Processing', 'Paid', 'Cancelled'];

    return (

        <SiteWide title={"Deposit History"}>
            <Section back="/trade" sectionstyle={{}} loading={loading} title={'Deposit History'}>

                <div className="goal-area pd-bottom-36">
                    <div className="containe" style={{paddingRight: 20, paddingLeft: 20}}>
                        
                       

                        <ul className="transaction-inner">
                            {loadHistoryData?.slice(0, 100)?.map((item, index) => {

                                    let mainResultTally = item;

                                    return <li className="ba-single-transaction style-two bg-black-grad">
                                        <div className="thumb" style={{backgroundColor: 'transparent', border: 0}}>
                                            <Images style={{height: '70px'}} alt={`USDT Deposit`} source={require(`../images/tron.png`)} />
                                        </div>
                                        <div className="details">
                                            <h5 className="text-white bebas-text">{money(item?.amountEnforcedToPay, item?.currency)}</h5>
                                            <small className="text-info bebas-text">Status: {status[item?.status]} </small><br />
                                            <small className="text-info bebas-text">Amount Paid: {money(item?.amountPaid, item?.currency)} </small>

                                        </div>
                                    </li>
                                }
                            )}
                        </ul>
                        
                        {loadHistoryData?.length == 0 && !loading && <div>
                                <h5 className="text-info">No deposit history found. <br />
                                <span style={{cursor:'pointer'}} onClick={() => setEnterAmountModal(true)}>Fund Account <i className="fa fa-plus"></i></span></h5>

                            </div>
                        }



                    </div>
                </div>

                <Footer />

            </Section>


            

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"

                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEsc={false}
                  >
                  
                    <div className="modal-content bg-warnig">
                        <div className="modal-header">
                            <h4 className="modal-title">Fund deposit</h4>
                            {/*<button type="button" className="close" onClick={closeModal}>
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">close</span>
                            </button>*/}
                        </div>
                        <div className="modal-body" style={{padding: '0 !important'}}>
                            <div className="contact-f-box text-center" style={{}}>
                                <label className="single-input-wrap">
                                    <span className="text-dark">{newDepositInfo?.[0]?.describe_currency}</span>
                                    <span>
                                        
                                        {newDepositInfo?.[0]?.address}

                                        {" "}<CopyToClipboard text={newDepositInfo?.[0]?.address}
                                            onCopy={() => notify('Address copied', 1)}>
                                            <i className="fa fa-copy text-info cursor-pointer"></i>
                                        </CopyToClipboard>
                                    </span>
                                </label>
                                
                                <Images src={newDepositInfo?.[0]?.qrCode} />
                                
                                <br />
                                <span className="h5 text-info">Pay exactly <br />
                                {newDepositInfo?.[0]?.amountEnforcedToPay} USDT

                                {" "}<CopyToClipboard text={newDepositInfo?.[0]?.amountEnforcedToPay}
                                    onCopy={() => notify('Amount copied', 1)}>
                                    <i className="fa fa-copy text-info cursor-pointer"></i>
                                </CopyToClipboard>

                                </span>
                            </div>
                        </div>
                        <div className="modal-footer">

                            
                            {/*<button onClick={closeModal} disable={loading}  type="button" className="btn btn-default">
                            {loading && <i className="fa fa-cog fa-spin"></i>} Close <i className="fa fa-times"></i></button>*/}

                            {newDepositInfo?.[0]?.status == 0 && <button onClick={() => window.confirm('Are you sure you want to delete') && deleteInv(newDepositInfo?.[0]?.txn_id)} disable={deleting} type="button" className="btn btn-danger">
                            {deleting && <i className="fa fa-cog fa-spin"></i>} {deleting?'Deleting...':'Delete'} <i className="fa fa-trash"></i></button>}
                            
                        
                        </div>
                    </div>


                    
                </Modal>

                
                <Modal
                    isOpen={enterAmountModal}
                    onRequestClose={() => setEnterAmountModal(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                  
                    <LoadingOverlay
                        active={paymentPlaceLoading}
                        spinner
                    >
                        <div className="modal-content bg-warnig">
                            <div className="modal-header">
                                <h4 className="modal-title">Fund deposit</h4>
                                <button type="button" className="close" onClick={() => setEnterAmountModal(false)}>
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">close</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{padding: '0 !important'}}>
                                <form className="contact-f-box" style={{}}>
                                    <label className="single-input-wrap">
                                        <span className="text-dark">Enter Fund Amount</span>
                                        <input value={amount} placeholder="USDT Amount" onChange = {e => setAmount(e.target.value) } required="" className="auth-input" type="text" />

                                    </label>
                                </form>
                            </div>
                            <div className="modal-footer">

                                <button onClick={openModal}  type="button" className="btn btn-info">
                                {loading && <i className="fa fa-cog fa-spin"></i>} Continue <i className="fa fa-long-arrow-right"></i></button>
                            
                            </div>
                        </div>

                    </LoadingOverlay>
                    
                </Modal>

            

        </SiteWide>
    );
}

export default DepositHistory;