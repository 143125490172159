import React, {useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import Helper1, {notify} from '../utility/Helper1';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Modal from 'react-modal';
import Colors from '../utility/Colors';

import {Images} from "./customComponents";
import {capitalizeFirstLetter, money} from '../utility/Helper1';

    

function UserSetting(){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [walletModal, setWalletModal]   = useState(false);
    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [password, setPassword]       = useState('');

    const [wallet, setWallet]       = useState(userData?.trc20tetherwallet ?? '');
    const [withdrawalPin, setWithdrawalPin]     = useState('');


    const dispatch = useDispatch();

    const doAddressChange = async (e) => {
        setloading(true);
        e.preventDefault();
        dispatch(authActions.onLogin("account/account_change", {wallet, withdrawalPin}, response => {
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

        
    }



    const doPasswordChange = async (e) => {

        if(password == ''){
            notify('Please input new password', 0);
            return false;
        }
        setloading(true);
        e.preventDefault();
        dispatch(authActions.onLogin("account/change_password", {password:password}, response => {
            if(response.code == 1){
                setPassword('');
            }
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

        
    }

    const saveBTNJSX = (callback) => (
        <>

            <div onClick={callback}  className="cursor-pointer" style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: 10, width: '120px', padding: 5, backgroundColor: '#fff', borderRadius: 30,}}>
                <Images alt={'bot Management'} source={require(`../images/icon-n.png`)} width={30} />
                <span style={{lex: 1}} className="bebas-text text-dark">UPDATE {loading && <i className="fa fa-cog fa-spin"></i>}</span>
            </div>

        </>
        )


    return (
        <SiteWide title={`Wallet update`}>
            <Section loading={loading} title={'Wallet update'}>

                <div className="transaction-details pd-top-36 mg-bottom-35" style={{ackgroundColor: Colors.all.greyBgHolder_1, overflowY: 'auto'}}>
                    <div className="container">
                        


                        <div className="mde">
                            <div className="mdal-contnt bg-warnig">
                                





                                <div className="mdal-body" style={{padding: '0 !important'}}>
                                    <form className="contact-f-box" style={{}}>
                                        

                                        <div class="sinrap" style={{padding: 10, marginTop: 12}}>
                   
                                            <span className="new-input-label" style={{color: '#fff', marginBottom: 20}}>USDTTRC20 Wallet Address</span>

                                            <div className="inp" style={{paddingTop: 20}}>

                                                <input type="text" className="form-field form-control" onChange = {(e) => setWallet(e.target.value)} value={wallet} style={{backgroundColor: Colors.all.greyBgHolder_1, border: '1px solid #fff', height: 60, color: '#fff'}} />

                                            </div>
                                        </div>


                                        <div class="sinrap" style={{padding: 10, marginTop: 12}}>
                   
                                            <span className="new-input-label" style={{color: '#fff', marginBottom: 20}}>Account Pin</span>

                                            <div className="inp" style={{paddingTop: 20}}>

                                                <input maxLength={4} type="password" className="form-field form-control" onChange = {(e) => setWithdrawalPin(e.target.value)} value={withdrawalPin} style={{backgroundColor: Colors.all.greyBgHolder_1, border: '1px solid #fff', height: 60, color: '#fff'}} />

                                            </div>
                                        </div>


                                    </form>
                                </div>

                                <div className="" style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
                                    {saveBTNJSX(doAddressChange)}
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>


            </Section>
        </SiteWide>
    );

}

export default UserSetting;