import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';


function* onLogin(action) {
  try {

    const response = yield (new APIRequests).runPost(action.endPointToCall, action.params);

    if (response.code != 0) {

      response.data.user.token = response.access_token;
      yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });
      yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      yield put({ type: actionTypes.SAVE_REMOTE_SITE_SETTINGS, settings: response.data.settings }); 

    }
    return action?.callback?.(response);
  } catch (error) {
    return action?.callback?.(error.response ?? error.message);
  }
}




function* onSignUp(action) {
  try {
    const response = yield (new APIRequests).runPost(action.endPointToCall, action.params);

    if (response.code != 0) {

      response.data.user.token = response.access_token;
      yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });
      yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      yield put({ type: actionTypes.SAVE_REMOTE_SITE_SETTINGS, settings: response.data.settings }); 

    }
    return action.callback?.(response);
  } catch (error) {
    return action.callback?.(error.response ?? error.message);
  }
}


function* onVerifyEmail(action) {
  try {
    const response = yield (new APIRequests).runPost("account/verify_email", action.params);

    if (response.code == 1) {
      response.data.user.token = response.access_token;
      yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });
      yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance }); // seed data eventhough, it is empty

    }
    return action.callback?.(response);
  } catch (error) {
    return action.callback?.(error.response ?? error.message);
  }
}


function* onResendVerifyEmail(action) {
  try {
    const response = yield (new APIRequests).runGet("account/resend_email_verification", action.params);
    if (response.code == 1) {

      response.data.user.token = response.access_token;

      yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });

    }
    return action.callback?.(response);
  } catch (error) {
    return action.callback?.(error.response ?? error.message);
  }
}


function* onReloadSystemData(action) {


  try {

    const response = yield (new APIRequests).runGet(action.endPointToCall);


    yield put({ type: actionTypes.SAVE_REMOTE_SITE_SETTINGS, settings: response.data.settings }); 
    response.data.user.token = response.access_token;

    yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
    
    if (action.enforceLogout == 100) {
      
      yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });
      

    }

    return action.callback?.(response);

  } catch (error) {
    return action.callback?.(error.response ?? error.message);
  }

}



// function* onLogout(action) {
function* onLogout() {

  yield put({ type: actionTypes.CLEAR_USER_DATA});
  yield put({ type: actionTypes.CLEAR_HOME_SCREEN_STAT});

}



function* watchLogin() {
  yield takeEvery(actionTypes.LOGIN, onLogin);
}



function* watchLogout() {
  yield takeEvery(actionTypes.LOGOUT, onLogout);
}




function* watchSignUp() {
  yield takeEvery(actionTypes.SIGN_UP, onSignUp);
}


function* watchVerifyEmail() {
  yield takeEvery(actionTypes.VERIFY_EMAIL, onVerifyEmail);
}

function* watchResendVerifyEmail() {
  yield takeEvery(actionTypes.RESEND_VERIFY_EMAIL, onResendVerifyEmail);
}


function* watchReloadAccountData() {
  yield takeEvery(actionTypes.RELOAD_SYSTEM_DATA, onReloadSystemData);
}



export default function* authSagas() {
  yield all([watchLogout(), watchLogin(), watchSignUp(), watchVerifyEmail(), watchResendVerifyEmail(), watchReloadAccountData()]);
}
