import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {Images} from "./customComponents";

    

function About(){

    const site_settings = useSelector(allRemoteSettingsSelect);
console.log(site_settings)
    return (
        <SiteWide title={"About us"}>
            <Section allNotification={false} searchPopup={true} title={'About'}>
                {/* transaction start */}
                <div className="transaction-area pd-top-36">
                    <div className="container">

                        <div className="about-content-inner p-0">
                            <Images className="w-100" src={site_settings?.image_settings?.[0]?.image1} alt="About us imagery" />
                        </div>
                        <div className="about-content-inner">
                            <h5>{site_settings?.content_settings?.[0]?.con1}</h5>
                            <p>{site_settings?.content_settings?.[0]?.con2}</p>
                        </div>
                        <div className="about-content-inner">
                            <h5>{site_settings?.content_settings?.[0]?.con3}</h5>
                            <p>{site_settings?.content_settings?.[0]?.con4}</p>
                        </div>
                        <div className="about-content-inner">
                            <h5>{site_settings?.content_settings?.[0]?.con5}</h5>
                            <p>{site_settings?.content_settings?.[0]?.con6}</p>
                        </div>
                        
                    </div>
                </div>
                {/* transaction End */}
            </Section>
        </SiteWide>
    );

}

export default About;