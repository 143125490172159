import React, {Component, useState, useEffect} from "react";

import Colors from '../../utility/Colors';




import styled from 'styled-components';



export default function KeyboardView(props) {

    const [amount, setAmount]   = useState('');

    const updateAmount = (newValue) => {
        setAmount(newValue)
        props.getActiveValue(newValue)
    }

    const handleKeyPress = (number) => {
        if(amount == '' && number === 0){ return; }
        if(amount == '' && number === '.'){ return; }
        let newValue = String(amount) + String(number)
        updateAmount(newValue)
    }

    const backspace = () => {
        const str = amount;
        const newStr = str.slice(0, -1); // "1234"
        updateAmount(newStr)
    }

    const KeyboardContainer = styled.div`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    `;

    const KeyButton = styled.button`
      width: 80px;
      height: 80px;
      border: none;
      border-radius: 8px;
      margin: 10px;
      font-size: 24px;
      font-weight: bold;
      background-color: ${Colors.all.greyBgHolder_1};
      box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.15);
      cursor: pointer;
      color: #eee;
      &:focus {
        outline: none;
      }
    `;





    return (

        <div>
            <KeyboardContainer>
              <KeyButton onClick={() => handleKeyPress(1)}>1</KeyButton>
              <KeyButton onClick={() => handleKeyPress(2)}>2</KeyButton>
              <KeyButton onClick={() => handleKeyPress(3)}>3</KeyButton>
              <KeyButton onClick={() => handleKeyPress(4)}>4</KeyButton>
              <KeyButton onClick={() => handleKeyPress(5)}>5</KeyButton>
              <KeyButton onClick={() => handleKeyPress(6)}>6</KeyButton>
              <KeyButton onClick={() => handleKeyPress(7)}>7</KeyButton>
              <KeyButton onClick={() => handleKeyPress(8)}>8</KeyButton>
              <KeyButton onClick={() => handleKeyPress(9)}>9</KeyButton>
              <KeyButton onClick={() => handleKeyPress(0)}>0</KeyButton>
              <KeyButton onClick={() => handleKeyPress('.')}>.</KeyButton>
              <KeyButton onClick={backspace}>←</KeyButton>
            </KeyboardContainer>
        </div>
    );
}



