import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, money, niceDate} from '../utility/Helper1';

import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "./customComponents";


function Referrals() {    

    const [loading, setLoading]         = useState(false)
    const [dataForLevel1, setLevel1]    = useState([])
    const [dataForLevel2, setLevel2]    = useState([])
    const [dataForLevel3, setLevel3]    = useState([])
    const [viewingForLevel1, setViewingForLevel1]   = useState(0)
    const [viewingForLevel2, setViewingForLevel2]   = useState(0)
    const [viewingForLevel3, setViewingForLevel3]   = useState(0)
    const [leaderboardData, setLeaderboardData]     = useState([])
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);


    const dispatch = useDispatch();

    const loadDownLines = (myrefid, level) => {
        
        setLoading(true)

        if(level == 1){
            setViewingForLevel1(myrefid);
            setViewingForLevel2(0);
            setViewingForLevel3(0);

        }else if(level == 2){
            setViewingForLevel2(myrefid);
            setViewingForLevel3(0);
        }else{
            setViewingForLevel3(myrefid);
        }

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
            

            setLoading(false)

            if(level == 1){
                setLevel1(response?.data?.referrals)

            }else if(level == 2){
                setLevel2(response?.data?.referrals)
            }else{
                setLevel3(response?.data?.referrals)
            }

            setLeaderboardData(response?.data?.leaderboard)
        }))
    }
    
    useEffect(() => {
        loadDownLines(userData?.myrefid, 1);
    }, [])

    const userData = useSelector(userSelect);


    const affiliatePlace = (refObject, focusUserCondition, level, thiscolor, nextcolor) => {
        return <div className="col-6" style={{padding: 0}}>
            <table className={`table striped bordered hover ${thiscolor}`}>
              {/*<thead style={{backgroundColor: '#191970'}}>
                <tr>
                  <th>Level {level}</th>
                </tr>
              </thead>*/}
              <tbody>

                {refObject?.map((eachdownline, index) => (
                <tr>
                    <td className={focusUserCondition == eachdownline?.myrefid?nextcolor:''}><span className="cursor-pointer text-white" onClick={() => level<3 && loadDownLines(eachdownline?.myrefid, level+1)}>{eachdownline?.username}</span>
                        <br />
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <small className="text-white" style={{fontSize: 8}}>{niceDate(eachdownline?.date)}</small>
                            <small className="text-white" style={{fontSize: 8, marginLeft: 'auto'}}>{money(eachdownline?.withdrawnFromMe?.[0]?.[0]?.[level-1], finance?.[0]?.motherType)}</small>
                        </div>
                    </td>
                </tr>
                ))}
                

              </tbody>
            </table>
        </div>
    }


    return (<SiteWide title="Team">
        <Section allNotification={false} searchPopup={true} title={'Team'}>
            <div className="container-inner g-top-30">
                <div className="container-fluid">
                    <div className="row flex-row">
                        <div className="col-xl-12">
                            {/* Basic Tabs */}

                            <LoadingOverlay
                                active={loading}
                                spinner
                            >

                                <div className="widget has-shadow" style={{backgroundColor: 'transparent'}}>
                                    
                                    <div className="widget-body sliding-tabs">
                                        <ul className="nav nav-tabs" id="example-one" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="base-tab-1" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Referrals</a>
                                            </li>
                                            
                                            <li className="nav-item">
                                                <a className="nav-link" id="base-tab-2" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Team Notice</a>
                                            </li>
                                            
                                        </ul>
                                        <div className="tab-content pt-3">
                                            
                                            <div className="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="base-tab-1">
                                                <div className="row">
                                                        
                                                        {affiliatePlace(dataForLevel1, viewingForLevel2, 1, 'bg-my-blue', 'bg-my-blue')}
                                                        {affiliatePlace(dataForLevel2, viewingForLevel3, 2, 'bg-my-blue', 'bg-my-blue')}
                                                        
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="base-tab-2">
                                                
                                                <ul className="transaction-inner">

                                                    <p className="text-center h5 text-white bebas-text">
                                                        {site_settings?.content_settings?.[0]?.['con6']}
                                                    </p>

                                                    {1>2 && leaderboardData?.map((item, index) => {

                                                            let icon = ((index+1) <= 3)?index+1:4;

                                                            return <li style={{display: 'flex', marginBottom: '10px', flexDirection: 'row', backgroundColor: '#191970', alignItems: 'center', justifyContent: 'center'}} className="a-single-transaction syle-two">
                                                                

                                                                <div style={{padding: 5, borderLeft: "1px solid #070540"}} className="etails">
                                                                    <h5 className="text-white" style={{color: '#fff'}}>{item?.username}</h5>
                                                                    <h5 className={"amount text-center"} style={{color: '#fff', textAlign: 'center'}}>
                                                                        {/*<small>{item?.occurrences} Active Referral</small>*/}
                                                                    </h5>
                                                                </div>

                                                                <div style={{marginLeft: 'auto', padding: 5}} className="humb text-center">
                                                                    <Images style={{height: '70px'}} alt={`USDT Deposit`} source={require(`../images/leader_${icon}.png`)} />
                                                                    <br />
                                                                    <span style={{padding: 5, width: '30px', marginTop: "-50px !important", height: '30px', display: 'inline-block', textAlign: 'center', 
                                                                    backgroundColor: '#fff', borderRadius: '50%', height: '100%', fontWeight: 900, color: '#000'}}>
                                                                        {index+1}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        }
                                                    )}
                                                </ul>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </LoadingOverlay>
                            {/* End Basic Tabs */}
                        </div>
                        
                    </div>
                    {/* End Row */}
                </div>
            </div>
        </Section>
        </SiteWide>
    );

}

export default Referrals;