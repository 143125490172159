import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {Chart} from "./customComponents/Chart";
import Countdown from 'react-countdown';


import TradeViewChart from 'react-crypto-chart';
import {fxActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allFXSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import {Images} from "./customComponents";
import Helper1, {notify} from '../utility/Helper1';
import {authActions} from '../redux/actions';
import Modal from 'react-modal';
import Colors from '../utility/Colors';
import {capitalizeFirstLetter, money} from '../utility/Helper1';
import { BiLock, BiCircle, BiCheckCircle, BiTrendingUp, BiTrendingDown } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";

import "./custom-css/trade.css";

const centeredModal = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      overflowY: 'scroll'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: 'none',
        zIndex: 10,
        overflowY: 'scroll'

    },
};



const cryptoPairs = ['BTCUSDT', 'ETHUSDT', 'LTCUSDT']


function Trades(){
    
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [tradeModal, setTradeModal]   = useState(false);
    const [loadFXNow, setLoadFXNow]   = useState(true);
    const [selectedFx, setSelectedFx]   = useState('');
    const [downOrUp, setDownOrUp]   = useState();
    const [amount, setAmount]   = useState('');
    const [loading, setloading]   = useState(true);
    const [timeIsZero, setTimeIsZero]   = useState(false);
    const [focussedBoundary, setFocussedBoundary]   = useState([]);
    const [activeMinute, setActiveMinute]   = useState(1);


    const dispatch = useDispatch();
    const finance = useSelector(homeScreenStatSelect);
    const loadFxData = useSelector(allFXSelect);
    const loadUsers = useSelector(userSelect);

    const [allFxData, setAllFxData]   = useState(loadFxData);
    const [followTradePlan, setFollowTradePlan]   = useState(false);
    const [openPlanModal, setOpenPlanModal]   = useState(false);
    const [tradingCapital, setTradingCapital]   = useState('');
    const [fxId, setFxId]   = useState('');
    const [currentCryptoPair, setCurrentCryptoPair]   = useState('BTCUSDT');
    const [callFollowPlanFunction, setCallFollowPlanFunction]   = useState(false);
    const [tradeSubmissionCounts, setTradeSubmissionCounts]   = useState(0);


    // const isFollowingPlan = Number(allFxData?.tradingPlan?.current_stage) > 0 && followTradePlan
    const isFollowingPlan = followTradePlan


    const updatePair = (newPair) => {
        setCurrentCryptoPair(newPair)
    }

    const toggleTradingModal = () => {
        setOpenPlanModal(open => !open)
    }

    const disablePlan = () => {
        setFollowTradePlan(false)
        setOpenPlanModal(false)
        setAmount('')
    }



    const loadAllFXes = () => {

        if(!loadFXNow){ return; }

        setLoadFXNow(false);

        dispatch(fxActions.onGetFxHead(`fx/load_all/${activeMinute}`, {}, response => {

            if(response.code == 1){
                setloading(false)
                setAllFxData(response.data)

                if((response?.data?.tradingPlan?.current_stage ?? 0) > 0){
                    // setTradingCapital((allFxData?.tradingPlan?.capital_used ?? 0) == 0?'':allFxData?.tradingPlan?.capital_used)
                    setTradingCapital(response?.data?.tradingPlan?.capital_used)
                }

            }

            setLoadFXNow(true)
        }))

    }



    useEffect(() => {

        const interval = setInterval(() => loadAllFXes(), 10000)
        loadAllFXes()
        return () => {
          clearInterval(interval);
        }

    }, [])


    const setUpFXBoundary = (directNewMinute = null) => {
        let fb = loadFxData?.fx?.[`${directNewMinute ?? activeMinute}min`]?.filter((item, index) => 
            new Date(item.timeboundary).getTime() <= thisDate && new Date(item.timeEnd).getTime() >= thisDate);
        setFocussedBoundary(fb);
    }

    useEffect(() => {

        setUpFXBoundary()

    }, [loadFxData, timeIsZero])

   


    const thisDate = new Date().getTime();

    const timeUp = () => {
        setTimeout(() => {
            setTimeIsZero(!timeIsZero);
        }, 1000);

    }


    const previewSubmission = (upOrDown) => {

        if((isNaN(amount)) || (amount<=0)){
            notify('You must enter numeric capital', 0)
            return;
        }

        setDownOrUp(upOrDown); 
        setFxId(focussedBoundary?.[0]?.fxId)
        setTradeModal(true);

    }

    const submitFX = () => {
        

        setloading(true);
        setLoadFXNow(false);

        // let fxId = focussedBoundary?.[0]?.fxId;

        dispatch(authActions.onLogin(`fx/trade/${activeMinute}`, {cryptoSelected: currentCryptoPair, amount: isFollowingPlan?tradingCapital:amount, isFollowingPlan:isFollowingPlan?1:0, downOrUp, fxId}, response => {
                setloading(false)
                if(response.code == 1){
                    setAmount('')
                    setTradeModal(false)

                    setAllFxData(response.data) 
                    setTradeSubmissionCounts(iop => iop + 1)
                    if(response?.data?.tradingPlan?.current_stage > 0){
                        setCallFollowPlanFunction(e => !e)
                    }

                }
                
                setLoadFXNow(true);
                notify(response.msg, response.code);
            }),
        )

    }



    // if i trade now, auto select amount for the next stage. If not needed because i win the just concluded stage, the useeffect down will close it
    useEffect(() => {
        followPlan(true)
    }, [callFollowPlanFunction])


    // if i have been following plan and next trade is won by me, 
    // then auto close the following plan option because next amount is auto selected. To avoid confussion
    //this solution auto closes stage 1 new trade as it meets the auto-close criteria, i try adding 'followTradePlan' to the conditions below and see if it solves it
    //that didnt, so i try controlling it with setTradeSubmissionCounts

    useEffect(() => {

        if(isFollowingPlan && tradeSubmissionCounts > 0 && (allFxData?.tradingPlan?.current_stage == 0)){
            disablePlan()
        }

    }, [allFxData])



    const followPlan = (doNotOpenModal = false) => {
        if(tradingCapital == ''){ return; }
        setFollowTradePlan(true)

        setOpenPlanModal(false)

        const cStage = Number(allFxData?.tradingPlan?.current_stage);

        let newCapitalPerStage = soleTradingPlanCalculation()?.[cStage]
        setAmount(newCapitalPerStage?.stageCapital ?? '');
    }

    const newMinute = (item) => {
        setActiveMinute(item)
        setUpFXBoundary(item)
    }

    const usefulMinutes = [1, 3, 5, 30]



    const soleTradingPlanCalculation = () => {

        let newData = [];

        let hi = site_settings?.fx?.plans?.map((item) => {

            let capital = (item.capitalPercentage/100)*tradingCapital
            let profit = ((item.profitPercentage/100)*capital )+ capital

            newData = [...newData, {...item, stageCapital: capital, stageProfit: profit}]
        
        })

        return newData;
    }


    const toggleSubmitConfirmation = () => {
        setTradeModal(el => !el)
    }

    return (
        <SiteWide  title={"Trades"} paddingTop={0}>
            <Section 
                hideHeader={true} 
                sectionstyle={{paddingTop: '0px',margin:0, height: '100%', display: 'flex', flexDirection: 'column'}} 
                loading={loading} title={'Trades'}>
        


                <div style={{height: '8vh'}}>
                            

                    <div style={{display: 'flex', padding: 5, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between'}} className="">
                        
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                        
                            <div style={{display: 'flex', padding: 5, paddingLeft: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className="">

                                 {allFxData?.minutes?.map((item) => <button onClick={() => newMinute(item)}  
                                    style={{fontSize: 11, backgroundColor: item == activeMinute?Colors.all.bluey:Colors.all.greyBgHolder_1, color: '#fff', padding: 5, border: item == activeMinute?`1px solid ${Colors.all.bluey}`:'1px solid #b3b3b3', marginRight: 2}} 
                                    className="cursor-pointer">

                                    {item}Min 

                                </button>)}

                            </div>

                            {!openPlanModal && <div style={{display: 'flex', zIndex: 1, padding: 5, paddingLeft: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className="">

                                 {cryptoPairs?.map((item) => <button onClick={() => updatePair(item)}  
                                    style={{fontSize: 11, backgroundColor: 'transparent', color: '#fff', padding: 5, border: item == currentCryptoPair?`1px solid #b3b3b3`:'0px solid #b3b3b3', marginRight: 2}} 
                                    className="cursor-pointer">

                                    <Images className={item == currentCryptoPair?"flipper-3d":''} source={require(`../images/${item}.png`)} width={30} />

                                </button>)}

                            </div>}

                        </div>

                        <div style={{display: 'flex', padding: 5, paddingRight: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className="">

                             <WebLink to="/withdraw" onClick={() => {}}  
                                style={{fontSize: 11, backgroundColor: Colors.all.greyBgHolder_1, color: '#fff', padding: 6, border: '1px solid #b3b3b3', marginRight: 2}} 
                                className="cursor-pointer">

                                {money(finance?.[0]?.balance, finance?.[0]?.motherType)}

                            </WebLink>

                            <WebLink to={"/trade-history"}
                                style={{fontSize: 11, backgroundColor: Colors.all.bluey, color: '#fff', padding: 6, border: `1px solid ${Colors.all.bluey}`, marginRight: 2}} 
                                className="cursor-pointer">

                                Record

                            </WebLink>

                        </div>

                    </div>

                </div>






                {cryptoPairs?.map((item) => {

                    if(item!=currentCryptoPair){ return <></>}

                    return <Chart pair={item}/>

                })}
                




                    

                <div style={{height: '20vh', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div className="">
                        <div style={{paddingRight: 3}} className="">
                            

                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className="">

                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', 
                                    backgroundColor: isFollowingPlan?'#005a40':Colors.all.greyBgHolder_1, height: 60, padding: 10, flex: 1, marginRight: 2}} className="c">

                                    <label for="enterCapital" style={{order: '0.5px solid #fff', flex: 1}}>
                                        <small className="text-white">Enter capital ({money('', finance?.[0]?.motherType, {symbolOnly: true})}):</small><br />
                                        <input  readOnly={isFollowingPlan} value={amount} id="enterCapital" onChange={(e) => setAmount(e.target.value)} 
                                            style={{width: '100%', border: 0, backgroundColor: 'transparent', color: '#fff'}} type="text" required placeholder="Capital" />
                                    </label>

                                    <div onClick={toggleTradingModal} className="text-center cursor-pointer" style={{}}>
                                        {!isFollowingPlan && <BiCircle color="#ff7700" size={25} />}
                                        {isFollowingPlan && <BiCheckCircle color="#008d5b" size={25} />}
                                        {!isFollowingPlan && <div style={{fontSize: 8, color: '#fff'}}>Trading Plan</div>}
                                        {isFollowingPlan && <div style={{fontSize: 8, color: '#fff'}}>Following Plan</div>}
                                    </div>
                                </div>

                                <div style={{flex: 1, marginLeft: 2, height: 60, padding: 10, backgroundColor: Colors.all.greyBgHolder_1 }} className="c2">
                                    <div className="t-1" style={{order: '0.5px solid #fff'}}>
                                        
                                        <div style={{}}>
                                            <small className="text-white">{focussedBoundary?.[0]?.fxId}</small><br />
                                        </div>

                                        <div style={{}}>
                                            <Countdown
                                                    zeroPadTime={2}
                                                    onComplete={timeUp}
                                                    key={new Date(focussedBoundary?.[0]?.timeEnd)}
                                                    date={new Date(focussedBoundary?.[0]?.timeEnd)}
                                                    renderer={({ hours, minutes, seconds, completed }) => {
                                                      if (completed) {
                                                        // Render a completed state
                                                        // timeUp();
                                                        return <span></span>
                                                        // 
                                                      } else {
                                                        // Render a countdown
                                                        return <span className="text-white">{hours}:{minutes}:{seconds}</span>;
                                                      }
                                                    }}
                                                  />
                                        </div>

                                    </div>
                                </div>

                            </div>


                            <div style={{marginTop: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className="">

                                <button onClick={() => previewSubmission(0)}  
                                    style={{fontSize: 20, backgroundColor: Colors.all.orangeBgHolder_1, color: '#000', flex: 1, padding: 10, border: 'none', marginRight: 2}} 
                                    className="cursor-pointer bebas-text text-white">

                                    Small <BiTrendingDown />
                                </button>

                                <button onClick={() => previewSubmission(1)} 
                                    style={{fontSize: 20, backgroundColor: Colors.all.bluey, color: '#000', flex: 1, padding: 10, border: 'none', marginLeft: 2}} 
                                    className="cursor-pointer bebas-text text-white">

                                    Big <BiTrendingUp />
                                </button>

                            </div>

                            


                        </div>
                        
                    </div>
                </div>


                <Modal
                    isOpen={openPlanModal}
                    onRequestClose={toggleTradingModal}
                    style={centeredModal}
                    contentLabel="Trading Modal"
                    shouldCloseOnOverlayClick={false}
                >
                    
                    <div class="container-fluid" style={{overflowY: 'scroll', backgroundColor: 'transparent', position: 'relative', zIndex: 99999999}}>
                        <div class="container p-5">
                            <div class="row">
                                
                                <div class="col-lg-12 col-md-12 mb-4">
                                  <div class="card card1 h-100" style={{}}>
                                    <div class="card-body">
                                     
                                        <h5 class="card-title">Stage {Number(allFxData?.tradingPlan?.current_stage) + 1}</h5>

                                        {(allFxData?.tradingPlan?.capital_used ?? 0) == 0 && <small class='text-muted'>Update capital</small>}
                                        {(allFxData?.tradingPlan?.capital_used ?? 0) != 0 && <small class='text-muted'>Capital locked <BiLock /></small>}
                                        
                                        <br /><br />

                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            
                                            <span style={{color: '#474747', fontWeight: 700, fontSize: 40}}>{money('', finance?.[0]?.motherType, {symbolOnly: true})}</span>

                                            <input maxlength="6" 
                                                readOnly={allFxData?.tradingPlan?.current_stage !== 0} 
                                                value={(allFxData?.tradingPlan?.capital_used ?? 0) != 0?allFxData?.tradingPlan?.capital_used:tradingCapital} 
                                                onChange={(e) => {if(!isNaN(e.target.value)){setTradingCapital(e.target.value)}}} 
                                                style={{width: '100%', border: 0, backgroundColor: 'transparent', color: '#474747', fontWeight: 700, height: 40, fontSize: 40}} 
                                                type="text" 
                                                required 
                                                placeholder="" />
                                        </div>


                                        <div style={{width: '100%', padding: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                            <hr style={{marginLeft: 0, marginTop: 4, width: 40, borderWidth: 5, borderColor: "#474747", display: 'inline', alignSelf: 'left'}} />
                                        </div>

                                        <small className="text-dark" style={{marginTop: -5}}>
                                            {site_settings?.content_settings?.[0]?.['con5']} Minimum trading capital <strong>{money(site_settings?.fx?.raw?.minimumTradingPlanCapital, finance?.[0]?.motherType)}</strong>
                                        </small>

                                        <br /><br />
                                        
                                        <span style={{fontWeight: 900}}></span>

                                        <ul>
                                            <li style={{display: 'flex', flexDirection: 'row', fontWeight: 900}}>
                                                <span style={{flex: 1}}></span>
                                                <span style={{flex: 2}}>CAPITAL</span>
                                                {/*<span style={{flex: 2}}>ROI</span>*/}
                                                <span style={{flex: 2}}>EARN</span>
                                            </li>
                                        </ul>


                                            {soleTradingPlanCalculation()?.map((item) => {

                                                const pinkBg = (allFxData?.tradingPlan?.current_stage == 0 && tradingCapital !='' && 1 == item.stage) || 

                                                (allFxData?.tradingPlan?.current_stage > 0 && (Number(allFxData?.tradingPlan?.current_stage) + 1) == item.stage)

                                                return <li style={{backgroundColor: pinkBg?'#ff95e9':'#fff', marginBottom: 4, padding: 4, display: 'flex', flexDirection: 'row'}}>
                                                    <span style={{flex: 1}}>{item.stage}</span>
                                                    <span style={{flex: 2}}>{money(item.stageCapital, finance?.[0]?.motherType, {alreadyRupeeSoDoNotConvertToRupee: true})}</span>
                                                    {/*<span style={{flex: 2}}>{tradingCapital == ''?'0.00':item.profitPercentage}%</span>*/}
                                                    <span style={{flex: 2}}>{money(item.stageProfit, finance?.[0]?.motherType, {alreadyRupeeSoDoNotConvertToRupee: true})}</span>
                                                    
                                                </li>})}


                                        <div class="d-grid my-3">
                                            <button onClick={followPlan} class="btn btn-outline-dark btn-block" style={{backgroundColor: '#212529', color: '#fff'}}>Follow Plan</button>
                                            <button onClick={disablePlan} class="btn btn-outline-dark btn-block" style={{backgroundColor: '#fff', color: '#000'}}>Disable Plan</button>
                                        </div>

                                    </div>

                                    
                                  </div>
                                </div>


                            </div>    
                        </div>
                    </div>

                </Modal>







                <Modal
                    isOpen={tradeModal}
                    onRequestClose={toggleSubmitConfirmation}
                    style={centeredModal}
                    contentLabel="Trade Confirmation"
                    shouldCloseOnOverlayClick={true}
                >
                    
                    <div class="container-fluid" style={{backgroundColor: 'transparent'}}>
                        
                            <div class="col-lg-12 col-md-12 mb-4">

                                <div onClick={toggleSubmitConfirmation} className="text-center cursor-pointer" style={{padding: 10}}>
                                    <AiOutlineCloseCircle  color="#fff" size={30} />
                                </div>

                                <div class="card card1 h-100" style={{background: Colors.all.bluey, backgroundColor: Colors.all.bluey, borderWidth: 0}}>
                                    <div class="card-body" style={{background: 'transparent', backgroundColor: Colors.all.bluey, borderWidth: 0}}>
                                     
                                        <h5 class="card-title text-white">Are you sure you want to place trade?</h5>

                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <small class='text-white bebas-text h4'>FXID: {fxId}</small>
                                            <small class='text-white bebas-text h4'>{activeMinute}-Minute</small>
                                        </div>

                                        <br /><br />

                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            
                                            <span style={{color: '#fff', fontWeight: 700, fontSize: 40}}>{money('', finance?.[0]?.motherType, {symbolOnly: true})}{amount}</span>

                                            <div style={{marginLeft: 10}}>
                                                {downOrUp == 0 && <BiTrendingDown size={35} color="red" />}
                                                {downOrUp == 1 && <BiTrendingUp size={35}  color="green" />}
                                            </div>
                                        </div>

                                        <div style={{width: '100%', padding: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                            <hr style={{marginLeft: 0, marginTop: 4, width: 40, borderWidth: 5, borderColor: "#fff", display: 'inline', alignSelf: 'left'}} />
                                        </div>


                                        <div class="d-grid my-3">
                                            <button onClick={submitFX} disabled={loading} class="btn btn-outline-dark btn-block" style={{backgroundColor: '#212529', color: '#fff'}}>Submit</button>
                                        </div>

                                    </div>

                                </div>

                            </div>

                    </div>

                </Modal>


            </Section>
        </SiteWide>
    );

}

export default Trades;