import React from 'react';

const headerColor  = "#000000";
const footerColor = "#000000";
const sideMenu2 = "#000000";

const all = {
  bluey: '#1866fb',
  header: '#000000',
  footer: '#000000',
  blue: 'linear-gradient(to right, #1666fc , #1853c0)',
  // blue: 'linear-gradient(to right, #1a1a1a , #1c1c1c)',
  whiteTextHolder_1: '#fff',
  blackTextHolder_1: '#000',
  greyBgHolder_1: '#1a1a1a',
  orangeBgHolder_1: '#f70',
}



export default {
  headerColor,
  footerColor,
  sideMenu2,
  all
}