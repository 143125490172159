import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {useHistory} from 'react-router-dom';
import Colors from '../utility/Colors';

import {authActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import Helper1, {notify} from '../utility/Helper1';
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import Countdown from 'react-countdown';


function UserVerification(){

    const userData = useSelector(userSelect);

    const [emailCodeInput, setEmailCodeInput] = useState('') 
    const [phoneCodeInput, setPhoneCodeInput] = useState('') 
    const [verifyingEmailOrPhone, setVerifyingEmailOrPhone] = useState(0)

    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const history                                       = useHistory();

    const dispatch = useDispatch();

    const verifyEmail = (e) => {
        e.preventDefault();

        setFullPageLoading(true);
        
        dispatch(authActions.onSignUp("account/verify_email", {emailCode:emailCodeInput}, response => {

            if(response.code == 1){
                if((userData?.emailVerification == 1) && (userData?.phoneVerification == 1)){
                    history.replace('/user-setting');
                }else{
                    setVerifyingEmailOrPhone(1)
                }
            }

            notify(response.msg, response.code)
            setFullPageLoading(false);
        
        }),
        );

    }


    const resendEmailVerification = (e) => {
        e.preventDefault();

        setFullPageLoading(true);
        
        dispatch(authActions.onRefreshSystemData("account/resend_email_verification", 100, {}, response => {
            notify(response.msg, response.code)
            setFullPageLoading(false);
        }),
        );
    }


    const resendPhoneVerification = (e) => {
        e.preventDefault();

        setFullPageLoading(true);
        
        dispatch(authActions.onRefreshSystemData("account/resend_phone_verification", 100, {}, response => {
            notify(response.msg, response.code)
            setFullPageLoading(false);
        }),
        );
    }



    const verifyPhone = (e) => {
        e.preventDefault();

        setFullPageLoading(true);
        
        dispatch(authActions.onSignUp("account/verify_phone", {smsCode:phoneCodeInput}, response => {

            if(response.code == 1){
                history.replace('/user-setting');
            }

            notify(response.msg, response.code)
            setFullPageLoading(false);
        
        }),
        );

    }



    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }

    useEffect(() => {
        if(userData?.emailVerification == 0){
            setVerifyingEmailOrPhone(0)
        }else if(userData?.phoneVerification == 0){
            setVerifyingEmailOrPhone(1)
        }
    }, [])

    return (
        <SiteWide title="Account verification">
            <Section loading={fullPageLoading} allNotification={false} searchPopup={true} title={'Verification'}
            >
                

                <div className="signin-area mg-bottom-35">
                    <div className="container">


                        {verifyingEmailOrPhone == 0 && <form className="verification-inner text-center" style={{height: 200, backgroundColor: Colors.all.bluey, ackgroundImage: "url(assets/img/bg/14.png)"}}>
                            <h3 className="text-white">Enter Email Verification Code</h3>
                            <input onChange={e => setEmailCodeInput(e.target.value) } type="text" placeholder="" />
                            
                            <div>
                                <span>
                                    <Countdown
                                        zeroPadTime={2}
                                        key={new Date(userData?.lastEmailTime)}
                                        date={new Date(userData?.lastEmailTime)}
                                        renderer={({ hours, minutes, seconds, completed }) => {
                                          if (completed) {
                                            // Render a completed state
                                            // timeUp();
                                            return <span onClick={resendEmailVerification} className="design-text-for-click">Resend Email OTP</span>
                                            // 
                                          } else {
                                            // Render a countdown
                                            return <span>Resend after {minutes}:{seconds}</span>;
                                          }
                                        }}
                                    />
                                </span>

                            </div>

                            <div className="verify-btn" style={{marginTop: 10}}>
                            
                                <button onClick={verifyEmail} className="btn btn-dark btn-block uth-input">Verify Email</button>
                            </div>
                        </form>
                        }


                        {verifyingEmailOrPhone == 1 && <form className="verification-inner text-center" style={{ackgroundImage: "url(assets/img/bg/14.png)"}}>
                            <h3>
                                Enter Phone Verification OTP
                            </h3>
                            <input onChange={e => setPhoneCodeInput(e.target.value) } type="text" placeholder="" />
                            
                            <div>
                                <span>
                                    <Countdown
                                        zeroPadTime={2}
                                        key={new Date(userData?.lastSMSTime)}
                                        date={new Date(userData?.lastSMSTime)}
                                        renderer={({ hours, minutes, seconds, completed }) => {
                                          if (completed) {
                                            // Render a completed state
                                            // timeUp();
                                            return <span onClick={resendPhoneVerification} className="design-text-for-click">Resend Phone OTP</span>
                                            // 
                                          } else {
                                            // Render a countdown
                                            return <span>Resend after {minutes}:{seconds}</span>;
                                          }
                                        }}
                                    />
                                </span>

                            </div>

                            <div className="verify-btn">

                                <button onClick={verifyPhone} className="btn btn-danger btn-block uth-input">Verify Phone</button>

                                
                            </div>

                            

                        </form>
                        }


                    </div>


                    <div className="text-center">
                        <span style={{cursor:'pointer', color: '#fff'}} onClick={() => doLogOutNowNow()}>Log out </span>
                    </div>

                </div>
            </Section>
        </SiteWide>
    );

}

export default UserVerification;