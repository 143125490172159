import React, {useState} from "react";

import {WebLink, Images} from "../customComponents";
import { removeUserSession, setUserSession, setUserFinance, setUserToken} from '../../utility/CommonAuth';
import  {useHistory} from 'react-router-dom';
import Colors from '../../utility/Colors';
import {notify} from '../../utility/Helper1';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer } from 'react-toastify';
import APIRequests from '../../utility/APIRequests';
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { userSelect } from '../../redux/selectors/index';
import {authActions} from '../../redux/actions';

  

    


function Navbar() {

    const userData = useSelector(userSelect);
    const dispatch = useDispatch();


    const hiddenFileInput = React.useRef(null);

    const [uploadingAvatar, setUploadingAvatar]           = useState(false);
    const history                                       = useHistory();

    const progClickFileUpload = () => {
        hiddenFileInput.current.click();
    }




    const instantUploadPreview = (file, destID) => {

        let output = document.getElementById(destID);

        // if(!Array.isArray(file)){ 
            // typeof file === 'object'
        if(file.type == undefined){
            output.src = file;
        

        }else{

            output.src = URL.createObjectURL(file);
            output.onload = function() {
                  URL.revokeObjectURL(output.src) // free memory
                }

        }


    };


    const uploadAvatar = async (file) => {

        // instantUploadPreview(file, 'avatarPlace');
        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )

        
    }


    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }
    
    const activeBackgroundColor = {backgroundColor: Colors.sideMenu2}
    const pathname = window.location.pathname

    const menuItems = [
        {address: '/', title: 'Home'},
        {address: '/components', title: 'Market List'},
        {address: '/carts', title: 'Trading'},
        {address: '/user-setting', title: 'History'},
        {address: '/notification', title: 'Results'},
        {address: '/notification', title: 'Account'},
        {address: '/notification', title: 'Referral'},
        {address: '/notification', title: 'Activities'},
        {address: '/notification', title: 'News Broadcast'},
        {address: '/notification', title: 'Policy'},
    ]



    return (
        <div className="ba-navbar">
            <ToastContainer />
            <div className="ba-navbar-user">
                <div className="menu-close">
                    <i className="la la-times" />
                </div>

                
                    <div className="thumb">
                        <LoadingOverlay
                            active={uploadingAvatar}
                            spinner
                        >
                        <Images style={{width:'60px', height:'60px'}} id="avatarPlace" title="Click to upload" className="cursor-pointer img-thumbnail" onClick={() => progClickFileUpload()} width="60px" source={(userData?.image !== null)?userData?.image:require('../../images/avatar.png')} alt="avatar" />
                        <input style={{display: 'none'}} ref={hiddenFileInput} onChange={(e) => uploadAvatar(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
                        </LoadingOverlay>
                    </div>
                

                <div className="details">
                    <h5>{userData?.username}</h5>
                    <p>
                        Ref ID: &nbsp;

                        <CopyToClipboard text={userData?.myrefid}
                          onCopy={() => notify('Ref ID copied', 1)}>
                          <span>
                            {userData?.myrefid} &nbsp;&nbsp;&nbsp;<i className="fa fa-copy text-primary cursor-pointer"></i>
                          </span>
                        </CopyToClipboard>

                    </p>
                </div>
            </div>
            
            
            <div className="ba-main-menu">
                <ul>
                    {menuItems.map((items, index) => (
                        <li className={pathname==items.address?'active':''} style={pathname==items.address?activeBackgroundColor:{}}><WebLink to={items.address}>{items.title}</WebLink></li>
                    ))}
                    
                </ul>
                <WebLink className="btn btn-info" onClick={doLogOutNowNow}>Log Out</WebLink>
            </div>
        </div>
    );
}


export default Navbar;