import React from "react";
import {Link} from "react-router-dom";


export default function MobileView(props){

	const {children, ...rest} = props;

	return <div className="row g-0 no-gutters" style={{padding: 0, margin: 0, backgroundColor: 'transparent'}}>

            <div className="col-md-3 col-lg-4 d-none d-md-block" style={{padding: 0, margin: 0, margin:0, padding: 0}}></div>

            <div  style={{margin:0}} className="main-breaker-body col-sm-12 col-md-6 col-lg-4">
                {props.children}
            </div>

            <div className="col-md-3 col-lg-4  d-none d-md-block" style={{padding: 0, margin: 0, backgroundColor: props.thirdBackgroundColor ?? 'transparent', 
            zIndex: 99}}>
            </div>


        </div>

}

