import { all } from "redux-saga/effects";
import authSagas from "./auth";
import fxSagas from "./fx";
import depositSagas from "./deposit";
import withdrawalSagas from "./withdrawal";



export default function* rootSaga() {
  yield all([
    authSagas(),
    fxSagas(),
    depositSagas(),
    withdrawalSagas(),
  ]);
}
