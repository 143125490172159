import React, {Component, useState, useEffect} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";


import Section from "../layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, money, moneyStatus} from '../../utility/Helper1';

import {depositActions, withdrawalActions, authActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import { AiOutlineWallet, AiFillCaretDown, AiOutlineCopy, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import KeyboardView from "../customComponents/KeyboardView";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import MobileView from '../customComponents/MobileView';

const amountSuggestion = [10, 20, 50, 100, 200, 500, 800, 1000];


function Withdrawal() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]   = useState('Loading history...');

    const [isOpen, setOpen] = useState(false);

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);

    const finance = useSelector(homeScreenStatSelect);
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const [currencyModal, setCurrencyModal]   = useState('');

    const allDepositData = useSelector(allDepositSelect);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);

    const [googleAuth, setGoogleAuth]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [activeBalanceType, setActiveBalanceType]     = useState(0);

    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);

    const search = useLocation();
    const depositRequest = search?.state?.deposit;

    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    

    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);


    const processWithdrawal = (e) => {
        
        e.preventDefault();

        if((userData?.trc20tetherwallet?.length ?? 0) < '5'){ 
            notify('Please bind your wallet address before withdrawal.');
            return false;
        }

        if((isNaN(amount)) || (amount == 0)){
            // notify('Enter amount', 0);
            return false;
        }


        if(withdrawalPin == ''){
            notify('Please enter your withdrawal pin', 0);
            return false;
        }


        setloading(true);
        setLoadingText('Requesting withdrawal');

        dispatch(withdrawalActions.onCreateWithdrawal({googleAuth, withdrawalPin, amount: amount, currency: finance?.[0].motherType, walletAddress}, `withdrawal/new/${activeBalanceType}`, response => {
            
            notify(response.msg, response.code)
            setloading(false);

            if(response.code == 1){
                // setAmount('');
                setWithdrawalPin('');
                // setGoogleAuth('');
                // history.push('/withdrawal-records')
            }
            
        }))
    }





    const reloadUserInfo = () => {

        dispatch(authActions.onLogin(`account/reload/user/info`, {}, response => {

                if(response.code == 1){

                }
               
                
            }),
        )


    }

    useEffect(() => {
        reloadUserInfo()
    }, [])


    const handleKeyPress = (number) => {
        setAmount(number)
    }


    const changeBalanceType = (obje) => {
        setActiveBalanceType(obje.id)
    }

    const balanceType = [
        {id: 0, label: 'Wallet', balance: finance?.[0]?.balance},
        {id: 1, label: 'Commission', balance: finance?.[0]?.commission}
    ]

    const newWithdrawal = () => {

        return <div>

                <MobileView>



                <div className="" style={{background: Colors.all.bluey, padding: 10, paddingBottom: 20, borderRadius: 8}}>

                    <div style={{}}>

                        <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            
                            <div style={{color: Colors.all.whiteTextHolder_1}}>
                                {balanceType?.[activeBalanceType]?.label}
                            </div>

                            <span class="cursor-pointer">
                                
                                <div className="menu">

                                    <Popup
                                      trigger={<div className="menu-item"><AiFillCaretDown size={20} color="#fff" /></div>}
                                      position="left top"
                                      on="click"
                                      closeOnDocumentClick
                                      mouseLeaveDelay={300}
                                      mouseEnterDelay={0}
                                      contentStyle={{ padding: '0px', border: 'none' }}
                                      arrow={false}
                                    >
                                      <div className="menu" style={{padding: 10}}>
                                        {balanceType?.map((item) => <div onClick={() => changeBalanceType(item)} className="menu-item cursor-pointer"> {item.label} </div>)}
                                      </div>
                                    </Popup>

                                  </div>
                            </span>

                        </div>

                        <div style={{color: Colors.all.whiteTextHolder_1, marginTop: 30}}>
                            The balance
                        </div>
                        <h1 style={{color: Colors.all.whiteTextHolder_1}}>
                            {money(balanceType?.[activeBalanceType]?.balance, finance?.[0]?.motherType)}
                        </h1>

                    </div>

                </div>





                <form style={{marginTop: 20}} action="">
                       
                    <h1 className="text-center text-white" style={{fontSize: 80, marginBottom: 50}}>
                        {money('', finance?.[0]?.motherType, {symbolOnly: true})}{amount}
                    </h1>
              
                    


                    <KeyboardView getActiveValue={handleKeyPress} />


                    <div class="sinrap" style={{padding: 10, marginTop: 12, marginBottom: 20}}>
                   
                        <span className="new-input-label" style={{color: '#fff', marginBottom: 20}}>ACCOUNT PIN</span>

                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div className="inp" style={{paddingTop: 20, flex: 2}}>

                                <input maxLength={4} autocomplete="new-password" type="password" className="form-field form-control" onChange = {(e) => setWithdrawalPin(e.target.value)} value={withdrawalPin} style={{backgroundColor: '#1a1a1a', border: '1px solid #fff', height: 58, color: '#fff'}} />

                            </div>

                            <div style={{flex: 1, marginLeft: 5}}>
                                <button type="submit" onClick={processWithdrawal} disabled={loading} className="cursor-pointer passion-font h3 tn t-40 tn btn-block btn-info" 
                                style={{border: 'none', fontSize: 14, padding: 20, backgroundColor: '#fd8c48', marginTop: 20}}>
                                    SUBMIT </button>
                            </div>

                        </div>
                    </div>

                    <br />


                </form>



                </MobileView>

            </div>


    }












    return (

        <SiteWide id="main" title={"Account Fund"}>
            <Section overlayText={loadingText} loading={loading} title={'Account Withdrawal'} style={{}}>
                
            
                    {newWithdrawal()}

            </Section>

        </SiteWide>
    );
}

export default Withdrawal;


